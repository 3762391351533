// import React, { Component, useState } from "react";
// import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView } from "react-native";
// import config from "../../config";
// import InputText from "../InputText";
// import PrimaryButton from "../PrimaryButton";
// import { Formik } from "formik";
// import * as yup from "yup";
// import AIcon from "react-native-vector-icons/AntDesign";
// import miscService from "../../services/misc.service";
// import { ActivityIndicator } from "react-native-web";
// import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
// const AddUpi = (props) => {
//   let [fontsLoaded] = useFonts({
//     Roboto_300Light,
//     Roboto_400Regular,
//   });
//   const [upiNumber, setUPINumber] = useState("");
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [upi_error_message, setUpiErrorMsg] = useState("");
//   const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);
//   const queryString = window.location.search;
//   const urlParams = new URLSearchParams(queryString);
//   const data = urlParams.get("data");
//   const handleConfirmUpiNumber = (upiNumber) => {
//     setUPINumber(upiNumber);
//   };
//   const initiateVouchUpiCollect = async ({ vpa }) => {
//     try {
//       const payload = await miscService.instantiate_vouch_UPI_collect({
//         data: data,
//         vpa: vpa,
//         // options: options,
//       });
//       console.log("upi_payload" + payload);
//       // if (payload.status == "initiated") {
//       //   // setVouchUPIOptions(options);
//       //   setVouchUPIOptions({});
//       //   setUPITimer(true);
//       // } else {
//       //   setIsMakingUpiPayment(false);
//       // }
//       return payload;
//     } catch (error) {
//       throw error;
//     }
//   };
//   const handleSubmit = async (values) => {
//     if (values.upiNumber.trim() != "") {
//       try {
//         setIsMakingUpiPayment(true);
//         var payload = await initiateVouchUpiCollect({ vpa: values.upiNumber.trim() });
//         console.log("Init Upi Payment");
//         console.log(payload);

//         if (payload.status == "failed to initialize") {
//           if (payload.message && payload.message.indexOf("Invalid VPA") != -1) {
//             payload.message = "Invalid UPI Id";
//           }
//           setUpiErrorMsg(payload.message);
//           setIsMakingUpiPayment(false);
//           return;
//         }
//         props.route.params.setUPITimer(true);
//         props.navigation.goBack();
//       } catch (err) {
//         setUpiErrorMsg(err.message);
//         throw err;
//       }
//       // props.route.params.onSuccess(values.upiNumber);
//     } else {
//       alert("please enter proper value");
//     }
//   };

//   const validationSchema = yup.object().shape({
//     upiNumber: yup
//       .string()
//       .label("upiNumber")
//       .required("This is a required field")
//       .trim("This is a required field")
//       .matches("[a-zA-Z0-9-+.-_]{2,256}@[a-zA-Z]{2,64}", "Invalid UPI ID"),
//   });
//   return (
//     <Formik
//       initialValues={{
//         upiNumber: upiNumber,
//       }}
//       onSubmit={(values) => {
//         handleSubmit(values);
//       }}
//       enableReinitialize={true}
//       validationSchema={validationSchema}
//     >
//       {({ handleChange, values, handleSubmit, errors, touched, handleBlur, isSubmitting }) => (
//         <SafeAreaView
//           style={props.route.params.customize_style("background", {
//             ...props.route.params.style,
//             flex: 1,
//             backgroundColor: "#fff",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100%",
//           })}
//         >
//           <View
//             style={props.route.params.customize_style("card", {
//               backgroundColor: "#FFF",
//               height: "100%",
//               paddingTop: (10 * props.route.params.scale) / 440,
//               width: props.route.params.scale * 0.98,
//             })}
//           >
//             <View
//               style={props.route.params.customize_style("banner", {
//                 flexDirection: "row",
//                 alignItems: "center",
//                 padding: (32 * props.route.params.scale) / 440,
//                 borderRadius: (15 * props.route.params.scale) / 440,
//                 fontColor: "#fff",
//               })}
//             >
//               <TouchableOpacity
//                 activeOpacity={0.6}
//                 onPress={() => {
//                   // props.onClose();
//                   props.navigation.goBack();
//                 }}
//               >
//                 <AIcon
//                   name="left"
//                   style={props.route.params.customize_style("banner", {
//                     color: "#fff",
//                     width: (16 * props.route.params.scale) / 440,
//                     height: (30 * props.route.params.scale) / 440,
//                     marginTop: (5 * props.route.params.scale) / 440,
//                     marginRight: (20 * props.route.params.scale) / 440,
//                     fontSize: (25 * props.route.params.scale) / 440,
//                   })}
//                 />
//               </TouchableOpacity>
//               <Text
//                 style={props.route.params.customize_style("banner", {
//                   fontSize: (20 * props.route.params.scale) / 440,
//                   fontFamily: "Roboto_400Regular",
//                   color: "#FFF",
//                 })}
//               >
//                 Add UPI
//               </Text>
//             </View>

//             <View style={{ justifyContent: "left" }}>
//               <Text
//                 style={props.route.params.customize_style("subText", {
//                   fontSize: (16 * props.route.params.scale) / 440,
//                   fontFamily: "Roboto_400Regular",
//                   color: "#AAA",
//                   paddingTop: (32 * props.route.params.scale) / 440,
//                   paddingBottom: (32 * props.route.params.scale) / 440,
//                   paddingHorizontal: (10 * props.route.params.scale) / 440,
//                 })}
//               >
//                 UPI ID is in the format of xxxxxx@psp
//               </Text>
//               <InputText
//                 name="upiNumber"
//                 label=" Add Existing UPI ID"
//                 placeholder="xyz@abc"
//                 onChangeText={handleChange("upiNumber")}
//                 onBlur={handleBlur("upiNumber")}
//                 error={touched.upiNumber && errors.upiNumber}
//                 value={values.upiNumber}
//                 customize_style={props.route.params.customize_style}
//                 scale={props.route.params.scale}
//               />
//               <View style={{ height: (20 * props.route.params.scale) / 440, width: "95%", paddingTop: (3 * props.route.params.scale) / 440 }}>
//                 {touched.upiNumber && errors.upiNumber && (
//                   <Text
//                     style={{
//                       fontSize: (12 * props.route.params.scale) / 440,
//                       textAlign: "right",
//                       fontFamily: "Roboto_400Regular",
//                       color: "#F90000",
//                     }}
//                   >
//                     {errors.upiNumber}
//                   </Text>
//                 )}
//               </View>
//               <PrimaryButton
//                 customize_style={props.route.params.customize_style}
//                 content={"CONFIRM"}
//                 clicked={handleSubmit}
//                 color={props?.color}
//                 loading={isMakingUpiPayment}
//                 scale={props.route.params.scale}
//               />
//               <Text style={{ padding: (32 * props.route.params.scale) / 440, color: "red", fontFamily: "Roboto_400Regular" }}>{upi_error_message}</Text>
//             </View>
//           </View>
//         </SafeAreaView>
//       )}
//     </Formik>
//   );
// };
// export default AddUpi;


import React, { Component, useEffect, useState } from "react";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import Lottie from "lottie-web";
import * as Device from 'expo-device';
import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView, ScrollView, ActivityIndicator, Dimensions } from "react-native";
import config from "../../config";
import InputText from "../InputText";
import PrimaryButton from "../PrimaryButton";
import { Formik } from "formik";
import * as yup from "yup";
import AIcon from "react-native-vector-icons/AntDesign";
import miscService from "../../services/misc.service";
import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { ProzaLibre_400Regular } from "@expo-google-fonts/proza-libre";
import { AntDesign } from "@expo/vector-icons";



const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const data = urlParams.get("data");

const get_width = (wdt) => {
  if (Device.deviceType == 1) return wdt
  else {
    if (wdt >= 1360) {
      return 0.45 * wdt
    } else {
      return 0.55 * wdt
    }
  }
}

const AddUpi = (props) => {
  let [fontsLoaded] = useFonts({
    ProzaLibre_400Regular,
    Roboto_300Light,
    Roboto_400Regular,
  });
  const [order_details, setOrderDetails] = useState({});

  const [windowSize, setWindowSize] = useState(get_width(Dimensions.get("window").width));
  const [upiNumber, setUPINumber] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [upi_error_message, setUpiErrorMsg] = useState("");
  const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const data = urlParams.get("data");



  useEffect(() => {

    const resizeListener = Dimensions.addEventListener("change", () => {
      setWindowSize(get_width(Dimensions.get("window").width));
      setRouteStyle({});
    });
    return () => resizeListener?.remove();

    console.log("done loading script");
  }, []);


  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#payment-error-lottie-div"),
      animationData: failedAnim,
      loop: true,
      autoplay: true,
    });
  }, []);

  const initiateVouchUpiCollect = async ({ vpa }) => {
    try {
      const payload = await miscService.instantiate_vouch_UPI_collect({
        data: data,
        vpa: vpa,
        // options: options,
      });
      console.log("upi_payload" + payload);
      // if (payload.status == "initiated") {
      //   // setVouchUPIOptions(options);
      //   setVouchUPIOptions({});
      //   setUPITimer(true);
      // } else {
      //   setIsMakingUpiPayment(false);
      // }
      return payload;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (values) => {
    if (values.upiNumber.trim() != "") {
      try {
        setIsMakingUpiPayment(true);
        var payload = await initiateVouchUpiCollect({ vpa: values.upiNumber.trim() });
        console.log("Init Upi Payment");
        console.log(payload);

        if (payload.status == "failed to initialize") {
          if (payload.message && payload.message.indexOf("Invalid VPA") != -1) {
            payload.message = "Invalid UPI Id";
          }
          setUpiErrorMsg(payload.message);
          setIsMakingUpiPayment(false);
          return;
        }
        props.route.params.setUPITimer(true);
        props.navigation.goBack();
      } catch (err) {
        setUpiErrorMsg(err.message);
        throw err;
      }
      // props.route.params.onSuccess(values.upiNumber);
    } else {
      alert("please enter proper value");
    }
  };

  const validationSchema = yup.object().shape({
    upiNumber: yup
      .string()
      .label("upiNumber")
      .required("This is a required field")
      .trim("This is a required field")
      .matches("[a-zA-Z0-9-+.-_]{2,256}@[a-zA-Z]{2,64}", "Invalid UPI ID"),
  });

  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        width: "100%",
        hieght: "100%",
        // ...routeStyle,
      }}
    >

      <ScrollView
        style={{ width: "100%" }}
        contentContainerStyle={{ alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        {/* <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
            position: "absolute",
            // ...routeStyle,
            height: "100%",
            zIndex: 9999,
            display: isMakingUpiPayment ? "flex" : "none",
          }}
        >
          <ActivityIndicator size="large" color={config.primary_color} />
        </View> */}
        <View style={{ width: "100vw", alignItems: "center", justifyContent: "center" }}>
          <View style={{
            width: windowSize,
            minHeight: '100svh',
            paddingHorizontal: [1, 2].includes(Device.deviceType) ? '1rem' : '4rem',
            paddingVertical: [1, 2].includes(Device.deviceType) ? '1rem' : '2rem',
            elevation: 5, // Add elevation for box shadow on Android
            shadowColor: 'black', // Specify shadow color for iOS
            shadowOffset: { width: 0, height: 2 }, // Specify shadow offset for iOS
            shadowOpacity: 0.2, // Specify shadow opacity for iOS
            shadowRadius: 4, // Specify shadow radius for iOS,
            fontFamily: "Roboto-Bold"
          }}>


            <Formik
              initialValues={{
                upiNumber: upiNumber,
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ handleChange, values, handleSubmit, errors, touched, handleBlur, isSubmitting }) => (

                <SafeAreaView
                  style={props.route.params.customize_style("background", {
                    ...props.route.params.style,
                    flex: 1,
                    backgroundColor: "#fff",
                    height: "100%",
                  })}
                >
                  <View
                    style={props.route.params.customize_style("card", {
                      backgroundColor: "#FFF",
                      height: "100%",
                      paddingTop: (10 * props.route.params.scale) / 440,
                      width: props.route.params.scale * 0.98,
                    })}
                  >


                    <View
                      style={props.route.params.customize_style("banner", {
                        flexDirection: "row",
                        alignItems: "center",
                        paddingHorizontal: '1rem',
                        paddingVertical: '2rem',
                        borderRadius: '0.625rem',
                        fontColor: "#fff",
                        width: '100%'
                      })}
                    >
                      <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={() => {
                          // props.onClose();
                          props.navigation.goBack();
                        }}
                      >
                        <AntDesign
                          name="arrowleft"
                          style={
                            props.route.params.customize_style("banner", {
                              fontSize: '1.5rem',
                              paddingRight: Device.deviceType == '1' ? '1rem' : '2rem',
                              paddingLeft: Device.deviceType == '1' ? '1rem' : '1rem',
                            })}
                        />
                      </TouchableOpacity>
                      <Text
                        style={props.route.params.customize_style("banner", {
                          fontSize: Device.deviceType == '1' ? '1rem' : '1.5em',
                          fontFamily: "Roboto_400Regular",
                          color: "#FFF",
                        })}
                      >
                        Add UPI
                      </Text>
                    </View>


                    <View style={{ justifyContent: "left" }}>
                      <Text
                        style={props.route.params.customize_style("subText", {
                          fontSize: Device.deviceType == 1 ? '0.875rem' : '1.25rem',
                          fontFamily: "Roboto_400Regular",
                          color: "#AAA",
                          paddingTop: '2rem',
                          paddingBottom: '2rem',
                        })}
                      >
                        UPI ID is in the format of "username@ban"
                      </Text>
                      <InputText
                        name="upiNumber"
                        label="Enter UPI ID"
                        placeholder="xyz@abc"
                        onChangeText={handleChange("upiNumber")}
                        onBlur={handleBlur("upiNumber")}
                        error={touched.upiNumber && errors.upiNumber}
                        value={values.upiNumber}
                        customize_style={props.route.params.customize_style}
                        scale={props.route.params.scale}
                      />
                      <View style={{ height: '2rem', width: "95%", paddingTop: (3 * props.route.params.scale) / 440 }}>
                        {touched.upiNumber && errors.upiNumber && (
                          <Text
                            style={{
                              fontSize: (12 * props.route.params.scale) / 440,
                              textAlign: "right",
                              fontFamily: "Roboto_400Regular",
                              color: "#F90000",
                            }}
                          >
                            {errors.upiNumber}
                          </Text>
                        )}
                      </View>
                      <PrimaryButton
                        customize_style={props.route.params.customize_style}
                        content={"Verify and pay"}
                        clicked={handleSubmit}
                        color={props?.color}
                        loading={isMakingUpiPayment}
                        scale={props.route.params.scale}
                      />
                      <Text style={{ padding: (32 * props.route.params.scale) / 440, color: "red", fontFamily: "Roboto_400Regular" }}>{upi_error_message}</Text>
                      <View style={{
                        borderRadius: '0.625rem',
                        elevation: 5, // Add elevation to create a shadow (works on Android)
                        shadowColor: 'black', // Shadow color (works on iOS)
                        shadowOffset: { width: 0, height: 2 }, // Shadow offset (works on iOS)
                        shadowOpacity: 0.2, // Shadow opacity (works on iOS)
                        shadowRadius: 5, // Shadow radius (works on iOS)
                        padding: '2rem',
                        marginTop: '4rem'
                      }}>
                        <Text style={{ fontSize: Device.deviceType == 1 ? '0.9375rem' : '1.25rem', marginBottom: '2rem' }}>
                          How UPI payment works
                        </Text>
                        <Text style={{ fontSize: Device.deviceType == 1 ? '0.75rem' : '1.125rem', color: '#737373', marginBottom: '2rem' }}>
                          1. Enter your VPA
                        </Text>
                        <Text style={{ fontSize: Device.deviceType == 1 ? '0.75rem' : '1.125rem', color: '#737373', marginBottom: '2rem' }}>
                          2. Recieve payment request on you bank app
                        </Text>
                        <Text style={{ fontSize: Device.deviceType == 1 ? '0.75rem' : '1.125rem', color: '#737373' }}>
                          3. Authorize payment request in your bank app
                        </Text>
                      </View>
                    </View>

                  </View>
                </SafeAreaView>
              )}
            </Formik>

          </View>
        </View>
      </ScrollView>

    </SafeAreaView>
  );
};

export default AddUpi;