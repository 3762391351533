import React, { Component, useRef, useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, SafeAreaView, ScrollView, ActivityIndicator } from "react-native";
import config from "../../config";
import InputText from "../InputText";
import PrimaryButton from "../PrimaryButton";
import { Formik } from "formik";
import * as yup from "yup";
import AIcon from "react-native-vector-icons/AntDesign";
import miscService from "../../services/misc.service";
import { Dimensions } from "react-native";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import Lottie from "lottie-web";
import { useFonts, ProzaLibre_400Regular } from "@expo-google-fonts/proza-libre";
import * as Device from 'expo-device';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const data = urlParams.get("data");


class CardDetailsOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      fg_errors: {},
      isMakingPayment: false,
    };
    // this.updateState = this.updateState.bind(this)
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.formRef);
    // if (_.isEqual(prevProps.fg_errors, this.props.fg_errors) == false) {
    //   if (this.props.fg_errors && Object.keys(this.props.fg_errors).length != 0) {
    //     this.prevCardNo = this.formRef.values.cardNumber;
    //     this.setState({ fg_errors: this.props.fg_errors });
    //     this.formRef.setFieldTouched("cardNumber", true, true);
    //   }
    // }
    const script = this.addScript({
      src: "https://spg-prod-cdn.freecharge.in/dist/main.js",
      id: "freecharge_main",
      onLoad: () => {
        console.log("Freecharge Script Loaded");
      },
    });
    console.log(this.formRef);
    console.log(this.lastSubmitValues);
    console.log(this.state.fg_errors);
    if (this.formRef) {
      this.formRef.setFieldTouched("cardNumber", true, true);
    }
    return true;
  }

  hidden_div = () => (
    <>
      <div id="pay-nb" hidden>
        <form id="payment_form"></form>
      </div>
      <button id="pay-nb-btn" hidden>
        Pay
      </button>
    </>
  );
  addScript = ({ src, id, onLoad }) => {
    const existing = document.getElementById(id);
    if (existing) {
      return existing;
    } else {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        if (onLoad) {
          onLoad();
        }
      };
      document.body.appendChild(script);
      return script;
    }
  };

  initiate_card_checkout = async (user_payment_detials, mode = "DC") => {
    var card = {};
    card["nameOnCard"] = user_payment_detials.nameOnCard;
    card["cardNumber"] = user_payment_detials.cardNumber;
    card["cvv"] = user_payment_detials.cvv;
    card["expiryMonth"] = user_payment_detials.expiry.slice(0, 2);
    card["expiryYear"] = user_payment_detials.expiry.slice(-4);
    try {
      const payload = await miscService.get_freecharge_netbanking_link({ data: data, bank_id: "na", mode: mode, card: card });
      console.log("freecharge link");
      console.log(payload);
      if (payload.data && payload.data.code && payload.data.code.startsWith("ERR_")) {
        setLoaders({ isError: true });
        setErrorMessage(payload.message);
        return;
      }
      try {
        var form = document.getElementById("payment_form");
        // form.action = payload.action;
        var form_attr = { ...payload };
        delete form_attr.action;
        var keys = Object.keys(form_attr);

        for (var i = 0; i < keys.length; i++) {
          switch (keys[i]) {
            case "subMode":
              var bankSelect = document.createElement("select");
              bankSelect.setAttribute("name", "bankSelect");
              bankSelect.setAttribute("class", "bankSelect");
              form.appendChild(bankSelect);
              var bankOption = document.createElement("option");
              bankOption.setAttribute("value", form_attr[keys[i]]);
              // bankOption.setAttribute("selected", true);

              bankOption.text = form_attr[keys[i]];
              bankSelect.appendChild(bankOption);
              bankSelect.value = form_attr[keys[i]];
              break;
            case "txnMode":
              var input = document.createElement("input");
              input.setAttribute("class", "paymentType");
              input.setAttribute("value", form_attr[keys[i]]);
              input.setAttribute("type", "hidden");
              form.appendChild(input);
              break;
            case "currency":
            case "env":
              break;
            default:
              var input = document.createElement("input");
              input.setAttribute("name", keys[i]);
              input.setAttribute("value", form_attr[keys[i]]);
              input.setAttribute("class", keys[i]);
              input.setAttribute("type", "hidden");
              form.appendChild(input);
          }
        }
        var submitButton = document.createElement("input");
        submitButton.setAttribute("class", "make_payment");
        submitButton.setAttribute("type", "submit");
        submitButton.style.visibility = false;
        form.appendChild(submitButton);
        var element = document.getElementById("pay-nb");

        console.log(element.innerHTML);
        FC.SetPay({
          paymentForm: "payment_form",
          enviroment: form_attr["env"],
          // two enviroment possible SANDBOX or PROD
          inputCallback: (event) => {
            console.log(event);
          },
          errorHandler: async (error) => {
            var msg = [];
            var fg_errors = {};
            for (var i = 0; i < error.length; i++) {
              switch (error[i].msg) {
                case "Invalid card number":
                  fg_errors.cardNumber = error[i].msg;
                  break;
                case "Invalid date":
                  fg_errors.expiry = error[i].msg;
                  break;
                case "Invalid CVV":
                  fg_errors.cvv = error[i].msg;
                  break;
                case "Invalid Name on Card":
                  fg_errors.nameOnCard = error[i].msg;
                  break;
              }
            }

            this.setState({ fg_errors: fg_errors });
            console.log(error);
          },
        });
        submitButton.click();
        // window.location=return_url;
      } catch (err) {
        this.setState({ isMakingPayment: false });
        console.log(err);
        // this.props.navigation.goBack();
        throw err;
      }
    } catch (error) {
      setLoaders((loaders) => ({ ...loaders, isError: true, isPaying: false }));
    }
  };

  handleSubmit = (values) => {
    if (/^3[47][0-9]{13}$/.test(values.cardNumber)) return alert("Sorry! American Express cards are Not Allowed");
    else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(values.cardNumber)) return alert("Sorry! Diners Club cards are Not Allowed");
    this.lastSubmitValues = values;
    this.setState({ isMakingPayment: true });
    this.initiate_card_checkout(values, this.props.cardType);
    this.setState({ isMakingPayment: false });
    // props.initPaymentCheckout(props.route.params,{method:'CARD',values:values})
  };

  customize_style = (event, style) => {
    return this.props.customize_style(event, style);
  };
  validationSchema = yup.object().shape({
    nameOnCard: yup.string().label("nameOnCard").trim().required("Name is Required"),
    cardNumber: yup
      .string()
      .matches(/^[0-9\/]+$/, "Only digits allowed")
      .label("cardNumber")
      .required("Card Number is Required")
      .max(16, "Card Number cant not have more than 16 characters")
      .trim()
      .min(13, "Card Number must at least have 13 characters"),
    expiry: yup
      .string()
      .label("expiry")
      .matches(/^[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/, "Must be MM/YYYY")
      .min(7, "Invalid Expiry Date")
      .max(7, "Invalid Expiry Date")
      .test("Validate Month/Year", "Invalid Expiry Date", function (value) {
        if (value.match(/^[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/) == null) {
          return true;
        }
        var month = value.substring(0, 2);
        month = Number.parseInt(month, 10);
        if (Number.isNaN(month)) {
          return false;
        }

        var year = value.substring(3);
        year = Number.parseInt(year, 10);
        if (Number.isNaN(year)) {
          return false;
        }

        if (month < 1 || month > 12) {
          return false;
        }
        var now = new Date();
        var currYear = now.getFullYear();
        var currMonth = now.getMonth() + 1;
        if (month < currMonth && currYear == year) {
          return false;
        }
        if (year < currYear) {
          return false;
        }
        return true;
      })
      .trim()
      .required("Expiry Date is Required"),
    cvv: yup
      .string()
      .matches(/^[0-9]+$/, "Only digits allowed")
      .label("cvv")
      .required("CVV is Required")
      .trim()
      .min(3, "CVV must at least have 3 characters")
      .max(4, "CVV cant not have more than 4 characters"),
  });

  validateForm = (values, props /* only available when using withFormik */) => {
    var errors = {};

    if (Object.keys(this.state.fg_errors).length != 0) {
      if (this.lastSubmitValues.cardNumber == this.formRef.values.cardNumber && this.state.fg_errors.cardNumber) {
        errors.cardNumber = this.state.fg_errors.cardNumber;
      }
      if (this.lastSubmitValues.expiry == this.formRef.values.expiry && this.state.fg_errors.expiry) {
        errors.expiry = this.state.fg_errors.expiry;
      }
    }

    return this.validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        return errors;
      })
      .catch((err) => {
        // console.log(err);
        for (var i = 0; i < err.inner.length; i++) {
          if (errors.hasOwnProperty(err.inner[i].path) == false) errors[err.inner[i].path] = err.inner[i].message;
        }
        // console.log(errors);
        return errors;
      });
  };
  render() {
    var { upiNumber } = this.state;
    {
      this.hidden_div();
    }
    if (this.props.cardType == "DC") {
      var headerText = "Debit Card Details";
      var bannerSubText = "Only MasterCard/Visa is supported";
      var cardNumberLabel = "Debit Card Number";
    } else {
      var headerText = "Credit Card Details";
      var bannerSubText = "Only MasterCard/Visa is supported";
      var cardNumberLabel = "Credit Card Number";
    }
    return (
      <Formik
        initialValues={{
          nameOnCard: "",
          cardNumber: "",
          expiry: "",
          expiryYear: "",
          cvv: "",
        }}
        innerRef={(f) => (this.formRef = f)}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        freecharge_errors={this.state.fg_errors}
        validate={(values, props) => {
          return this.validateForm(values, props);
        }}
      >
        {({ handleChange, values, handleSubmit, errors, touched, handleBlur, isSubmitting, setFieldValue }) => (
          <SafeAreaView
            style={this.props.customize_style("background", {
              // ...this.props.route.params.style,
              flex: 1,
              backgroundColor: "#fff",
              height: "100%",
            })}
          >
            <View
              style={this.props.customize_style("card", {
                backgroundColor: "#FFF",
                height: "100%",
                // paddingTop: (10 * this.props.scale) / 440,
                // width: this.props.scale * 0.98,
              })}
            >
              <View
                style={this.props.customize_style("banner", {
                  flexDirection: "row",
                  alignItems: "center",
                  paddingHorizontal: '1rem',
                  paddingVertical: '2rem',
                  borderRadius: '0.625rem',
                  fontColor: "#fff",
                  width: '100%'
                })}
              >
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => {
                    // this.props.comeBack();
                    this.props.goBack();
                  }}
                >
                  <AIcon
                    name="arrowleft"
                    style={this.customize_style("banner", {
                      color: "#fff",
                      fontSize: '1.5rem',
                        paddingRight: Device.deviceType == '1' ? '1rem' : '2rem',
                        paddingLeft: Device.deviceType == '1' ? '1rem' : '1rem',
                    })}
                  />
                </TouchableOpacity>
                <View style={{ alignItems: "Left" }}>
                  <Text
                    style={this.customize_style("banner", {
                      fontFamily: "Roboto_400Regular",
                      color: "#fff",
                      fontSize: Device.deviceType == '1' ? '1rem' : '1.5em',
                      fontFamily: "Roboto_400Regular",
                      color: "#FFF",
                    })}
                  >
                    {headerText}
                  </Text>
                  <Text
                    style={this.customize_style("banner", {
                      // fontSize: (14 * this.props.scale) / 440,
                      fontSize: Device.deviceType == '1' ? '0.75rem' : '1em',
                      fontFamily: "Roboto_400Regular",
                      color: "#fff",
                      paddingTop: (8 * this.props.scale) / 440,
                    })}
                  >
                    {bannerSubText}
                  </Text>
                </View>
              </View>

              <View style={{ paddingTop: (32 * this.props.scale) / 440, paddingHorizontal: (32 * this.props.scale) / 440 }}>
                <InputText
                  name="cardNumber"
                  iconName="creditcard"
                  label={cardNumberLabel}
                  placeholder="XXXXXXXXXXXXXXXX"
                  onChangeText={handleChange("cardNumber")}
                  onBlur={handleBlur("cardNumber")}
                  error={touched.cardNumber && errors.cardNumber}
                  value={values.cardNumber}
                  customize_style={this.customize_style}
                  scale={this.props.scale}
                />

                <View style={{ height: (20 * this.props.scale) / 440, width: "100%", paddingTop: (3 * this.props.scale) / 440 }}>
                  {touched.cardNumber && errors.cardNumber && (
                    <Text
                      style={{
                        fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9rem',
                        textAlign: "right",
                        fontFamily: "Roboto_400Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.cardNumber ? errors.cardNumber : "no errors"}
                    </Text>
                  )}
                </View>
                <InputText
                  name="nameOnCard"
                  iconName="idcard"
                  label="Name on card"
                  placeholder="Bruce Wayne"
                  onChangeText={handleChange("nameOnCard")}
                  onBlur={handleBlur("nameOnCard")}
                  error={touched.nameOnCard && errors.nameOnCard}
                  value={values.nameOnCard}
                  customize_style={this.customize_style}
                  scale={this.props.scale}
                />
                <View style={{ height: (20 * this.props.scale) / 440, width: "100%", paddingTop: (3 * this.props.scale) / 440 }}>
                  {touched.nameOnCard && errors.nameOnCard && (
                    <Text
                      style={{
                        fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9rem',
                        textAlign: "right",
                        fontFamily: "Roboto_400Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.nameOnCard}
                    </Text>
                  )}
                </View>
                <InputText
                  name="expiry"
                  iconName="calendar"
                  label="Valid till (MM/YYYY)"
                  placeholder="MM/YYYY"
                  maxLength={7}
                  onChangeText={(e) => {
                    if (e.length == 2 && values.expiry.length < e.length) {
                      setFieldValue("expiry", e + "/");
                    } else {
                      setFieldValue("expiry", e);
                    }
                  }}
                  onBlur={handleBlur("expiry")}
                  error={touched.expiry && errors.expiry}
                  value={values.expiry}
                  customize_style={this.customize_style}
                  scale={this.props.scale}
                />
                <View style={{ height: (20 * this.props.scale) / 440, width: "100%", paddingTop: (3 * this.props.scale) / 440 }}>
                  {touched.expiry && errors.expiry && (
                    <Text
                      style={{
                        fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9rem',
                        textAlign: "right",
                        fontFamily: "Roboto_400Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.expiry}
                    </Text>
                  )}
                </View>
                <InputText
                  name="cvv"
                  iconName="lock"
                  label="CVV"
                  placeholder="XXX"
                  onChangeText={handleChange("cvv")}
                  onBlur={handleBlur("cvv")}
                  error={touched.cvv && errors.cvv}
                  value={values.cvv}
                  customize_style={this.customize_style}
                  scale={this.props.scale}
                />
                <View style={{ height: (20 * this.props.scale) / 440, width: "100%", paddingTop: (3 * this.props.scale) / 440 }}>
                  {touched.cvv && errors.cvv && (
                    <Text
                      style={{
                        fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9rem',
                        textAlign: "right",
                        fontFamily: "Roboto_400Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.cvv}
                    </Text>
                  )}
                </View>
                <Text
                  style={{
                    fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9rem',
                    fontFamily: "Roboto_400Regular",
                    color: "#707070",
                    paddingBottom: (6 * this.props.scale) / 440,
                  }}
                >
                  This is a secure payment screen and your card details are not stored
                </Text>
                <PrimaryButton
                  content={"PAY"}
                  customize_style={this.customize_style}
                  theme={this.props.theme}
                  clicked={handleSubmit}
                  color={this.props?.color}
                  loading={this.state.isMakingPayment}
                  scale={this.props.scale}
                />
                {/* <View style={{ alignItems: "flex-end", width: "100%", paddingTop: 33, paddingBottom: 33, paddingRight: 42 }}>
                      <TouchableOpacity
                        onPress={handleSubmit}
                        style={this.customize_style("button", {
                          borderRadius: 32,
                          backgroundColor: "#481380",
                          paddingBottom: 8,
                          paddingTop: 8,
                          paddingHorizontal: 26,
                        })}
                      >
                        <Text style={this.customize_style("button", { fontSize: 15, color: "#fff" })}>Pay</Text>
                      </TouchableOpacity>
                    </View> */}
              </View>
            </View>
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginTop: 5,
    marginRight: 20,
    fontSize: 25,
  },
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  inputContainer: {
    paddingHorizontal: "8%",
    paddingBottom: "10%",
  },
});













const get_width = (wdt) => {
  if (Device.deviceType == 1) return wdt
  else {
    if (wdt >= 1360) {
      return 0.45 * wdt
    } else {
      return 0.55 * wdt
    }
  }
}

const CardDetailsOverlayContainer = (props) => {
  let [fontsLoaded] = useFonts({
    ProzaLibre_400Regular,
  });
  const [order_details, setOrderDetails] = useState({});

  const [windowSize, setWindowSize] = useState(get_width(Dimensions.get("window").width));
  const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);


  useEffect(() => {
    console.log('abc');
    console.log(props.route.params);
  })


  useEffect(() => {

    const resizeListener = Dimensions.addEventListener("change", () => {
      setWindowSize(get_width(Dimensions.get("window").width));
      setRouteStyle({});
    });
    return () => resizeListener?.remove();

    console.log("done loading script");
  }, []);


  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#payment-error-lottie-div"),
      animationData: failedAnim,
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        width: "100%",
        hieght: "100%",
        // ...routeStyle,
      }}
    >

      <ScrollView
        style={{ width: "100%", paddingHorizontal: (16 * windowSize) / 440 }}
        contentContainerStyle={{ alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
            position: "absolute",
            // ...routeStyle,
            height: "100%",
            zIndex: 9999,
            display: isMakingUpiPayment ? "flex" : "none",
          }}
        >
          <ActivityIndicator size="large" color={config.primary_color} />
        </View>
        <View style={{ width: "100vw", alignItems: "center", justifyContent: "center" }}>
          <View style={{
            width: windowSize,
            height: '100svh',
            paddingHorizontal: [1, 2].includes(Device.deviceType) ? '1rem' : '4rem',
            paddingVertical: [1, 2].includes(Device.deviceType) ? '1rem' : '2rem',
            elevation: 5, // Add elevation for box shadow on Android
            shadowColor: 'black', // Specify shadow color for iOS
            shadowOffset: { width: 0, height: 2 }, // Specify shadow offset for iOS
            shadowOpacity: 0.2, // Specify shadow opacity for iOS
            shadowRadius: 4, // Specify shadow radius for iOS,
            fontFamily: "Roboto-Bold"
          }}>
            <CardDetailsOverlay
              customize_style={props.route.params.customize_style}
              scale={props.route.params.scale}
              cardType={props.route.params.cardType}
              goBack={()=>{props.navigation.goBack()}}
            />
          </View>
        </View>
      </ScrollView>

    </SafeAreaView>
  );
};

export default CardDetailsOverlayContainer;