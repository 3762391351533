import React from "react";
import { Image, StyleSheet, View, TouchableOpacity, Text, ImageBackground } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import config from "../config";
import * as Device from 'expo-device';
import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";


const Header = (props) => {
  let [fontsLoaded] = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
  });

  var url =
    props.order_details && props.order_details.theme && props.order_details.theme.logo
      ? props.order_details.theme.logo
      : "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/Vouch.jpeg";
  var ImageBackgroundColor =
    props.order_details && props.order_details.theme && props.order_details.theme.banner && props.order_details.theme.banner.imageBackgroundColor
      ? props.order_details.theme.banner.imageBackgroundColor
      : "#fff";
  var bannerBackgroundColor =
    props.order_details && props.order_details.theme && props.order_details.theme.banner && props.order_details.theme.banner.backgroundColor
      ? props.order_details.theme.banner.backgroundColor
      : "#481380";
  var fontColor =
    props.order_details && props.order_details.theme && props.order_details.theme.banner && props.order_details.theme.banner.fontColor
      ? props.order_details.theme.banner.fontColor
      : "#fff";
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderRadius: '0.625rem',
        // paddingTop: (16 * props.scale) / 440,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        backgroundColor: bannerBackgroundColor,
        width: props.scale * 0.98,
      }}
    >
      <TouchableOpacity activeOpacity={0.6} onPress={() => history.back()}>
        <AntDesign
          name="arrowleft"
          style={{
            color: fontColor,
            fontSize: '1rem',
            paddingRight: Device.deviceType == '1'?'1rem':'2em',
          }}
        />
      </TouchableOpacity>
      <View style={{ alignItems: "flex-end", flexDirection: "row" }}>
        <View
          style={{
            backgroundColor: ImageBackgroundColor,
            height: Device.deviceType == '1'?'5.25rem':'6.25rem',
            width: Device.deviceType == '1'?'5.25rem':'6.25rem',
            borderRadius: '0.625rem',
            justifyContent: "center",
            alignItems: "center",
            padding:'0.625em',
            marginRight:'1rem'
          }}
        >
          <Image
            source={{
              uri: url,
            }}
            style={{
              height: Device.deviceType == '1'?'4.625rem':'5.625rem',
              width: Device.deviceType == '1'?'4.625rem':'5.625rem',
            }}
          />
        </View>
        <View
          style={{
            paddingLeft: (10 * props.scale) / 440,
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              flex: 1,
              fontSize: Device.deviceType == '1' ? '1.75rem' : '2rem',
              fontFamily: "Roboto_400Regular",
              fontStyle: "normal",
              color: fontColor,
              marginBottom:'0.5rem'
            }}
          >
            ₹{!Number.isNaN(Number(props.amount)) ? Number(props.amount).toLocaleString() : props.amount}
          </Text>
          <Text
            style={{
              flex: 1,
              // fontSize: (16 * props.scale) / 440,
              fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
              fontFamily: "Roboto_400Regular",
              color: fontColor,
            }}
          >
            Amount to be paid before charges
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backButton: {
    color: "#473BB3",
    fontSize: 22,
    paddingRight: 15,
  },
});

export default Header;
