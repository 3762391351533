import React from "react";
import { StyleSheet } from "react-native";
import GuestPaymentCheckout from "./src/pages/auth/GuestPaymentCheckout.js";
// import AddUpi from "./src/components/custom_checkout/AddUpi.js";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AddUpi from "./src/components/custom_checkout/AddUpi.js";
import MoreBanksOverlay from "./src/components/custom_checkout/MoreBanksOverlay.js";
import CardDetailsOverlay from "./src/components/custom_checkout/CardDetailsOverlay.js";

const Stack = createNativeStackNavigator();
export default function App() {
  // const [width, setWidth] = useState(Dimensions.get("window").width);
  // const [routeStyle, setRouteStyle] = useState({});

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="Checkout"
          component={GuestPaymentCheckout}
          options={{
            headerShown: false,
          }}
          // initialParams={{ ...routeStyle, order_details: order_data }}
        />
        <Stack.Screen
          name="UPI Details"
          component={AddUpi}
          options={{
            headerShown: false,
          }}
          // initialParams={{ ...routeStyle, order_details: order_data }}
        />

        <Stack.Screen
          name="DebitCard Details"
          component={CardDetailsOverlay}
          options={{
            headerShown: false,
          }}
          cardType="DC"
          // initialParams={{ ...routeStyle, order_details: order_data }}
        />

        <Stack.Screen
          name="NetBanking Select Bank"
          component={MoreBanksOverlay}
          options={{
            headerShown: false,
          }}
          // initialParams={{ ...routeStyle, order_details: order_data }}
        />

        <Stack.Screen
          name="CreditCard Details"
          component={CardDetailsOverlay}
          options={{
            headerShown: false,
          }}
          cardType="CC"
          // initialParams={{ ...routeStyle, order_details: order_data }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

// const styles = StyleSheet.create({
//   centeredView: {
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//     marginTop: 22,
//   },
//   modalView: {
//     margin: 20,
//     backgroundColor: "white",
//     borderRadius: 20,
//     padding: 35,
//     alignItems: "center",
//     shadowColor: "#000",
//     shadowOffset: {
//       width: 0,
//       height: 2,
//     },
//     shadowOpacity: 0.25,
//     shadowRadius: 4,
//     elevation: 5,
//   },
//   button: {
//     borderRadius: 20,
//     padding: 10,
//     elevation: 2,
//   },
//   buttonOpen: {
//     backgroundColor: "#F194FF",
//   },
//   buttonClose: {
//     backgroundColor: "#2196F3",
//   },
//   textStyle: {
//     color: "white",
//     fontWeight: "bold",
//     textAlign: "center",
//   },
//   modalText: {
//     marginBottom: 15,
//     textAlign: "center",
//   },
// });

// export default App;
