import axios from "axios";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";

class Interceptor {
  getObject = async () => {
    try {
      let axiosObj = axios;
      // firebase.auth().currentUser.getIdToken
      // const user = await firebase.auth().currentUser;
      // console.log(user);
      // const token = user ? await user.getIdToken(true) : null;
      // console.log(token);
      axiosObj.interceptors.request.use(config => {
        // if (user && token) {
        //   config.headers["Authorization"] = `Bearer ${token}`;
        // }
        config.headers["Content-Type"] = "application/json";
        return config;
      });

      return axiosObj;
    } catch (error) {
      // console.log(error);
    }
  };
}

export default new Interceptor();
