import config from '../config';
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

class LogService {
  logCustomEvent = async (name,params) => {
    try {
        // firebase.analytics().logEvent(name,params)
      return 
    } catch (error) {
      return error.response.data;
    }
  };
}

export default new LogService();
