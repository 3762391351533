import Header from "../Header";
import miscService from "../../services/misc.service";
import React, { Component, useState, useEffect } from "react";
import { View, Text, SafeAreaView, StyleSheet, ScrollView, FlatList, TouchableOpacity, Dimensions, ActivityIndicator } from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../../config";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import Lottie from "lottie-web";
import { useFonts, ProzaLibre_400Regular } from "@expo-google-fonts/proza-libre";
import * as Device from 'expo-device';

class MoreBanksOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderItem = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        onPress={() => {
          this.props.netBankingSelected(item.code);
          this.props.navigation.goBack();
        }}
        style={styles.dropDownViewTouchable}
      >
        <Text
          style={this.customize_style("header", {
            fontSize: 14,
            lineHeight: 21,
            color: "#3f5261",
            fontFamily: "Roboto_400Regular",
          })}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };
  customize_style = (event, style) => {
    return this.props.customize_style(event, style);
  };
  render() {
    return (
      <SafeAreaView
        style={this.customize_style("background", {
          flex: 1,
          backgroundColor: "#fff",
          // justifyContent: "center",
          // alignItems: "center",
          width: "100%",
        })}
      >
        <View
          style={this.customize_style("card", {
            // flex: 0.84,
            backgroundColor: "#fff",
            // borderTopLeftRadius: (14 * this.props.scale) / 440,
            // borderTopRightRadius: (14 * this.props.scale) / 440,
            // paddingTop: 0,

            height: "100%",
          })}
        >
          {/* <View style={{ flexDirection: "row", alignItems: "center", marginTop: 20, marginLeft: 20 }}> */}
          <View
            style={this.props.customize_style("banner", {
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: '1rem',
              paddingVertical: '2rem',
              borderRadius: '0.625rem',
              fontColor: "#fff",
              width: '100%'
            })}
          >
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.goBack();
              }}
            >
              <AIcon name="arrowleft" style={this.customize_style("banner", {
                color: "#fff",
                fontSize: '1.5rem',
                paddingRight: Device.deviceType == '1' ? '1rem' : '2rem',
                paddingLeft: Device.deviceType == '1' ? '1rem' : '1rem',
              })} />
            </TouchableOpacity>

            <Text
              style={this.customize_style("banner", {
                fontFamily: "Roboto_400Regular",
                color: "#fff",
                fontSize: Device.deviceType == '1' ? '1rem' : '1.5em',
                fontFamily: "Roboto_400Regular",
                color: "#FFF",
              })}
            >
              Select Your Bank
            </Text>
          </View>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={[
              { code: "0001", name: "Aditya Birla Payments Bank" },
              { code: "0002", name: "Airtel Payments Bank" },
              { code: "0003", name: "Allahabad Bank" },
              { code: "0004", name: "Andhra Bank" },
              { code: "0005", name: "AXIS Bank" },
              { code: "0006", name: "Bank of Baroda" },
              { code: "0007", name: "Bank of India" },
              { code: "0008", name: "Bank of Maharashtra" },
              { code: "0009", name: "Canara Bank" },
              { code: "0010", name: "Catholic Syrian Bank" },
              { code: "0011", name: "Central Bank of India" },
              { code: "0012", name: "Citibank" },
              { code: "0013", name: "City Union Bank" },
              { code: "0014", name: "Corporation Bank" },
              { code: "0015", name: "Cosmos Bank" },
              { code: "0016", name: "DCB Bank" },
              { code: "0017", name: "Dena Bank" },
              { code: "0018", name: "Deutsche Bank" },
              { code: "0019", name: "Federal Bank" },
              { code: "0020", name: "HDFC Bank" },
              { code: "0021", name: "ICICI Bank" },
              { code: "0022", name: "IDBI Bank" },
              { code: "0023", name: "IDFC Bank" },
              { code: "0024", name: "Indian Bank" },
              { code: "0025", name: "Indian Overseas Bank" },
              { code: "0026", name: "IndSusInd Bank" },
              { code: "0027", name: "J&K Bank" },
              { code: "0028", name: "Janata Sahakari Bank" },
              { code: "0029", name: "Karnataka Bank" },
              { code: "0030", name: "Karur Vysya Bank" },
              { code: "0031", name: "Kotak Mahindra Bank" },
              { code: "0032", name: "Lakshmi Vilas Bank" },
              { code: "0033", name: "Oriental Bank of Commerce" },
              { code: "0034", name: "P Retail" },
              { code: "0035", name: "Punjab and Sind Bank" },
              { code: "0036", name: "RBL Bank" },
              { code: "0037", name: "Saraswat Bank" },
              { code: "0038", name: "SBI Bank" },
              { code: "0039", name: "South Indian Bank" },
              { code: "0040", name: "Syndicate Bank" },
              { code: "0041", name: "Tamilnad Mercantile Bank" },
              { code: "0042", name: "UCO Bank" },
              { code: "0043", name: "Union Bank" },
              { code: "0044", name: "United Bank of India" },
              { code: "0045", name: "Vijaya Bank" },
              { code: "0046", name: "YES Bank" },
              { code: "0047", name: "Paytm Bank" },
              { code: "0048", name: "Standard Charter Bank" },
              { code: "0049", name: "Bandhan Bank" },
              { code: "0050", name: "Bank of Bahrain and Kuwait" },
              { code: "0051", name: "DBS Bank Ltd" },
              { code: "0052", name: "Dhanlaxmi Bank" },
              { code: "0053", name: "Punjab And Maharashtra Co-operative Bank Limited" },
              { code: "0054", name: "Shamrao Vithal Co-op. Bank Ltd" },
              { code: "0056", name: "Suryoday Small Finance Bank Ltd" },
              { code: "0057", name: "The Bharat Co-op. Bank Ltd" },
              { code: "0058", name: "The Nainital Bank" },
              { code: "0059", name: "Ujjivan Small Finance Bank" },
            ]}
            renderItem={({ item, index }) => this.renderItem(item, index)}
            keyExtractor={(item, index) => index.toString()}
            style={this.customize_style("card", { 
              paddingHorizontal: (55 * this.props.scale) / 440,
              paddingTop:'2rem' 
              // marginTop: (20 * this.props.scale) / 440 
            })}
            contentContainerStyle={{
              flexGrow: 1,
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  dropDownViewTouchable: {
    paddingTop: 15,
    paddingBottom: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#faf6f5",
    marginBottom: 5,
    // #4a4a4a
  },
  txt4: {
    fontSize: 14,
    lineHeight: 21,
    color: "#3f5261",
    fontFamily: "Roboto_400Regular",
  },
});


const get_width = (wdt) => {
  if (Device.deviceType == 1) return wdt
  else {
    if (wdt >= 1360) {
      return 0.45 * wdt
    } else {
      return 0.55 * wdt
    }
  }
}

const MoreBanksOverlayContainer = (props) => {
  let [fontsLoaded] = useFonts({
    ProzaLibre_400Regular,
  });
  const [order_details, setOrderDetails] = useState({});

  const [windowSize, setWindowSize] = useState(get_width(Dimensions.get("window").width));
  const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);


  useEffect(() => {
    console.log('abc');
    console.log(props.route.params);
  })


  useEffect(() => {

    const resizeListener = Dimensions.addEventListener("change", () => {
      setWindowSize(get_width(Dimensions.get("window").width));
      setRouteStyle({});
    });
    return () => resizeListener?.remove();

    console.log("done loading script");
  }, []);


  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#payment-error-lottie-div"),
      animationData: failedAnim,
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        width: "100%",
        hieght: "100%",
        // ...routeStyle,
      }}
    >

      <ScrollView
        style={{ width: "100%", paddingHorizontal: (16 * windowSize) / 440 }}
        contentContainerStyle={{ alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
            position: "absolute",
            // ...routeStyle,
            height: "100%",
            zIndex: 9999,
            display: isMakingUpiPayment ? "flex" : "none",
          }}
        >
          <ActivityIndicator size="large" color={config.primary_color} />
        </View>
        <View style={{ width: "100vw", alignItems: "center", justifyContent: "center" }}>
          <View style={{
            width: windowSize,
            height: '100svh',
            paddingHorizontal: [1, 2].includes(Device.deviceType) ? '1rem' : '4rem',
            paddingVertical: [1, 2].includes(Device.deviceType) ? '1rem' : '2rem',
            elevation: 5, // Add elevation for box shadow on Android
            shadowColor: 'black', // Specify shadow color for iOS
            shadowOffset: { width: 0, height: 2 }, // Specify shadow offset for iOS
            shadowOpacity: 0.2, // Specify shadow opacity for iOS
            shadowRadius: 4, // Specify shadow radius for iOS,
            fontFamily: "Roboto-Bold"
          }}>
            <MoreBanksOverlay
              customize_style={props.route.params.customize_style}
              scale={props.route.params.scale}
              cardType={props.route.params.cardType}
              goBack={() => { props.navigation.goBack() }}
              netBankingSelected={props.route.params.netBankingSelected}
              navigation={props.navigation}
            />
          </View>
        </View>
      </ScrollView>

    </SafeAreaView>
  );
};

export default MoreBanksOverlayContainer;