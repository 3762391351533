import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
import * as Device from 'expo-device';
import config from "../../config";
import Phonepay from '../../assets/images/phonepay'
import Gpay from '../../assets/images/gpay'
import Paytm from '../../assets/images/paytm'
import Bhim from '../../assets/images/bhim'

import { SvgUri, SvgXml } from 'react-native-svg';


const UpiOption = (props) => {
  let [fontsLoaded] = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
  });
  var [convenience_fees, setConvenienceFees] = useState(0);
  var [total_amount, setTotalAmount] = useState(0);
  if (props.order_details.convenience_fees && props.order_details.convenience_fees.UPI && convenience_fees == 0) {
    console.log("convenience fees : ".convenience_fees);
    setConvenienceFees("(Including " + (props.order_details.convenience_fees.UPI / props.order_details.convenience_fees / 100).toFixed(2) + "% Fee)");
    setTotalAmount(Number(Decimal(Number(props.order_details.amount)).add(Number(props.order_details.convenience_fees.UPI))));
  } else if (convenience_fees == 0) {
    setConvenienceFees("(Including 0% fee)");
    setTotalAmount(props.order_details.amount);
    console.log("total amount set");
  }
  var order_details = props.order_details;
  const applyCustomHStyle = props.customize_style;


  const render_upi_option_mobile_ui = () => {
    const text_style = { fontSize: '0.75rem', fontFamily: 'Roboto_400Regular', color: '#464646', marginTop: '1rem' }
    return (<>
      <>
        <Text style={{ fontSize: '0.75rem', fontFamily: "Roboto_400Regular", color: '#737373', marginVertical: '1rem' }}>
          Pay using UPI app on your phone or enter UPI ID to pay
        </Text>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', fontFamily: "Roboto_400Regular" }}>
          <TouchableOpacity
            onPress={() => { }}
            style={{ alignItems: 'center' }}>
            <Phonepay width={'2.5rem'} height={'2.5rem'} />
            <Text style={text_style}>Phonepe</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { }}
            style={{ alignItems: 'center' }}>
            <Gpay width={'2.5rem'} height={'2.5rem'} />
            <Text style={text_style}>GPay</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { }}
            style={{ alignItems: 'center' }}>
            <Paytm width={'2.5rem'} height={'2.5rem'} />
            <Text style={text_style}>Paytm</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { }}
            style={{ alignItems: 'center' }}>
            <Bhim width={'2.5rem'} height={'2.5rem'} />
            <Text style={text_style}>BHIM</Text>
          </TouchableOpacity>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TouchableOpacity
            onPress={() => {
              // props.addUpiPressed();
              props.navigation.push("UPI Details", { customize_style: applyCustomHStyle, setUPITimer: props.setUPITimer, scale: props.scale });
            }}
            style={applyCustomHStyle("button", {
              borderRadius: '2rem',
              fontSize: '1rem',
              backgroundColor: "#481380",
              paddingVertical: '0.5em',
              paddingHorizontal: '1.62em',
              marginTop:'1rem'
            })}
          >
            <Text style={applyCustomHStyle("button", {
              fontSize: Device.deviceType == '1' ? '0.625rem' : '1rem',
              color: "#fff"
            })}>ADD NEW UPI</Text>
          </TouchableOpacity>
        </View>
      </>
    </>)
  }


  return (
    <View
      style={applyCustomHStyle("card", {
        borderRadius: (10 * props.scale) / 440,
        borderWidth: 1,
        borderColor: "#DDDDDD",
        width: props.scale * 0.98,
        justifyContent: "space-between",
        borderRadius: '0.625rem',
        paddingHorizontal: Device.deviceType == '1' ? '1rem' : '2rem',
        paddingVertical: Device.deviceType == '1' ? '1rem' : '1rem',
      })}
    >
      <View style={{ flexDirection: "row", paddingBottom: '1.15rem' }}>
        <Text
          style={applyCustomHStyle("subText", {
            fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
            fontFamily: "Roboto_400Regular",
            color: "#000",
            marginRight:'2rem'
          })}
        >
          You will Pay ₹{!Number.isNaN(Number(total_amount)) ? Number(total_amount).toLocaleString() : total_amount}
        </Text>

        <Text
          style={applyCustomHStyle("subText", {
            // fontSize: (14 * props.scale) / 440,
            fontSize: Device.deviceType == '1' ? '0.625rem' : '0.875rem',
            fontFamily: "Roboto_400Regular",
            color: "#737373",
            paddingTop: (18 * props.scale) / 440,
            color: "#737373",
            paddingRight: (20 * props.scale) / 440,
          })}
        >
          {convenience_fees}
        </Text>
      </View>
      <View style={{ flexDirection: "row", alignItems: 'center' }}>
        <View
          style={{
            alignItems: "flex-start",
            width: "20%",
            paddingLeft: (20 * props.scale) / 440,
            paddingTop: (16 * props.scale) / 440,
            paddingBottom: (33 * props.scale) / 440,
          }}
        >
          <Text style={{
            fontSize: Device.deviceType == '1' ? '0.875rem' : '1.5rem',
            fontFamily: "Roboto_400Regular",
            color: "#$000"
          }}>UPI</Text>
        </View>
        {props.amount > 100000 && (

          <View
            style={{
              alignItems: "flex-end",
              width: "80%",
            }}
          >
            <View
              style={{
                backgroundColor: "#DDDDDD",
                borderRadius: '2rem',
                fontSize: '1rem',
                paddingVertical: '0.5em',
                paddingHorizontal: '1.62em',
              }}
            >
              <Text style={{
                fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
                color: "#fff"
              }}>Amount Above UPI LIMIT</Text>
            </View>
          </View>
        )}
        {props.amount <= 100000 && Device.deviceType != '1' && (
          <View
            style={{
              alignItems: "flex-end",
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <TouchableOpacity
              onPress={() => {
                props.qr_handler();
                // props.navigation.push("UPI Details", { customize_style: applyCustomHStyle, setUPITimer: props.setUPITimer, scale: props.scale });
              }}
              style={applyCustomHStyle("button", {
                borderRadius: '2rem',
                fontSize: '1rem',
                backgroundColor: "#481380",
                paddingVertical: '0.5em',
                paddingHorizontal: '1.62em',
                marginRight: '1em'
              })}
            >
              <Text style={applyCustomHStyle("button", {
                fontSize: Device.deviceType == '1' ? '0.625rem' : '1rem',
                color: "#fff"
              })}>SCAN QR & PAY</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.addUpiPressed();
                props.navigation.push("UPI Details", { customize_style: applyCustomHStyle, setUPITimer: props.setUPITimer, scale: props.scale });
              }}
              style={applyCustomHStyle("button", {
                borderRadius: '2rem',
                fontSize: '1rem',
                backgroundColor: "#481380",
                paddingVertical: '0.5em',
                paddingHorizontal: '1.62em',
              })}
            >
              <Text style={applyCustomHStyle("button", {
                fontSize: Device.deviceType == '1' ? '0.625rem' : '1rem',
                color: "#fff"
              })}>ADD NEW UPI</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      {props.amount <= 100000 && Device.deviceType == '1' && render_upi_option_mobile_ui()}
    </View>
  );
};

export default UpiOption;
