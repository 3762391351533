import React, { Component, useEffect, useState } from "react";
import { View, Text, ScrollView, StyleSheet, SafeAreaView, Image, ActivityIndicator } from "react-native";
import config from "../../config";
import MerchantImage from "../../assets/images/merchantImage";
import payment_success_light from "../../assets/lottie-animations/payment-success_light.json";
import payment_success_dark from "../../assets/lottie-animations/payment-success_dark.json";
import LottieView from "lottie-react-native";
import PrimaryButton from "../PrimaryButton";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import Lottie from "lottie-web";
import { Dimensions } from "react-native";
import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
import * as Device from 'expo-device';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const data = urlParams.get("data");

const get_width = (wdt) => {
  if (Device.deviceType == 1) return wdt
  else {
    if (wdt >= 1360) {
      return 0.45 * wdt
    } else {
      return 0.55 * wdt
    }
  }
}


const ShowError = (props) => {
  const timer_start = 10;
  const [timer, setTimer] = useState(timer_start);
  const [isRedirectClicked, setRedirectClicked] = useState(false);
  let [fontsLoaded] = useFonts({
    Roboto_300Light, 
    Roboto_400Regular
  });
  const [order_details, setOrderDetails] = useState({});
  const [windowSize, setWindowSize] = useState(get_width(Dimensions.get("window").width));
  const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);

  useEffect(() => {
    const resizeListener = Dimensions.addEventListener("change", () => {
      setWindowSize(get_width(Dimensions.get("window").width));
      setRouteStyle({});
    });
    return () => resizeListener?.remove();
    console.log("done loading script");
  }, []);


  // var lottie_height = (300 * props.scale) / 440;
  // var lottie_width = (300 * props.scale) / 440;
  const [scale, setScale] = useState(props.scale);
  const [lottie_height, setLottieHeight] = useState((500 * props.scale) / 440);
  const [lottie_width, setLottieWidth] = useState((500 * props.scale) / 440);

  const onWindowResize = (event) => {
    var new_width = event.target.innerWidth;
    setLottieHeight((250 * new_width) / 440);
    setLottieWidth((250 * new_width) / 440);
    setScale((200 * new_width) / 440);
    // console.log(`event : %o`, event);
    // console.log(`innerWidth : ${new_width}`);
    // console.log(`LottieHeight : ${lottie_height}`);
    // console.log(`LottieWidth : ${lottie_width}`);
    // console.log(`Scale : ${scale}`);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    Lottie.loadAnimation({
      container: document.querySelector("#payment-error-lottie-div"),
      animationData: failedAnim,
      loop: false,
      autoplay: true,
    });
    return () => window.removeEventListener("resize", () => { });
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        width: "100%",
        hieght: "100%",
        // ...routeStyle,
      }}
    >
      <ScrollView
        style={{ width: "100%", paddingHorizontal: (16 * windowSize) / 440 }}
        contentContainerStyle={{ alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
            position: "absolute",
            // ...routeStyle,
            height: "100%",
            zIndex: 9999,
            display: isMakingUpiPayment ? "flex" : "none",
          }}
        >
          <ActivityIndicator size="large" color={config.primary_color} />
        </View>
        <View style={{ width: "100vw", alignItems: "center", justifyContent: "center" }}>
          <View style={{
            width: windowSize,
            height: '100svh',
            paddingHorizontal: [1, 2].includes(Device.deviceType) ? '1rem' : '4rem',
            paddingVertical: [1, 2].includes(Device.deviceType) ? '1rem' : '2rem',
            elevation: 5, // Add elevation for box shadow on Android
            shadowColor: 'black', // Specify shadow color for iOS
            shadowOffset: { width: 0, height: 2 }, // Specify shadow offset for iOS
            shadowOpacity: 0.2, // Specify shadow opacity for iOS
            shadowRadius: 4, // Specify shadow radius for iOS,
            fontFamily: "Roboto-Bold"
          }}>
            <View style={{ alignItems: "center" }}>
              <div id="payment-error-lottie-div" style={{ width: Device.deviceType == '1'?'40rem':'45rem', alignItems: "center" }} />
            </View>
            <View
              style={props.customize_style("background", {
                flex: 1,
                // justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFF",
                paddingHorizontal: 20,
              })}
            >
              <PrimaryButton
                content={"Return to Merchant"}
                theme={props.order_details.theme}
                customize_style={props.customize_style}
                loading={isRedirectClicked}
                scale={scale}
                clicked={() => {
                  setRedirectClicked(true);
                  window.location.replace(props.order_details.redirect_url + "?data=" + props.order_details.link);
                }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  animationSize: { height: 400, aspectRatio: 16 / 9 },
  text: {
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign: "center",
    color: "#000000",
    lineHeight: 12,
    letterSpacing: 1,
  },
});

export default ShowError;