import React, { useState } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import Decimal from "decimal.js";
import config from "../../config";
import * as Device from 'expo-device';

const NetBanking = (props) => {
  var [convenience_fees, setConvenienceFees] = useState(0);
  var [total_amount, setTotalAmount] = useState(0);

  if (props.order_details.convenience_fees && props.order_details.convenience_fees.NB && convenience_fees == 0) {
    console.log("convenience fees : ".convenience_fees);
    setConvenienceFees("(Including " + ((props.order_details.convenience_fees.NB / props.order_details.amount) * 100).toFixed(2) + "% fee)");
    setTotalAmount(Number(Decimal(Number(props.order_details.amount)).add(Number(props.order_details.convenience_fees.NB))));
    console.log("convenience fees : ".convenience_fees);
  } else if (total_amount == 0) {
    setConvenienceFees("(Including 0% fee)");
    setTotalAmount(Number(Decimal(Number(props.order_details.amount))));
  }
  return (
    <View style={{}}>
      <View
        style={props.customize_style("card", {
          borderRadius: '0.625rem',
          borderWidth: (1 * props.scale) / 440,
          borderColor: "#DDDDDD",
          paddingHorizontal: Device.deviceType == '1' ? '1rem' : '2rem',
          paddingVertical: Device.deviceType == '1' ? '1rem' : '1rem',
        })}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
              fontFamily: "Roboto_400Regular",
              color: "#000",
              marginRight: '2rem'
            })}
          >
            You will Pay ₹{!Number.isNaN(Number(total_amount)) ? Number(total_amount).toLocaleString() : total_amount}
          </Text>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.625rem' : '0.875rem',
              fontFamily: "Roboto_400Regular",
              color: "#737373",
            })}
          >
            {convenience_fees}
          </Text>
        </View>
        <View
          style={{
            alignItems: "flex-start",
            width: "50%",
            // paddingLeft: (20 * props.scale) / 440,
            paddingTop: '0.62rem',
            // paddingBottom: (33 * props.scale) / 440,
          }}
        >
          <Text style={props.customize_style("header", {
            fontSize: Device.deviceType == '1' ? '0.625rem' : '1.5rem',
            fontFamily: "Roboto_400Regular", color: "#$000"
          })}>
            Net Banking
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            // paddingLeft: (20 * props.scale) / 440,
            // paddingTop: 0,
            // paddingBottom: (33 * props.scale) / 440,
            // paddingRight: (33 * props.scale) / 440,
            paddingHorizontal: Device.deviceType == '1' ? '0.62rem' : '0rem',
            paddingVertical: Device.deviceType == '1' ? '0.5rem' : '2rem'
          }}
        >
          <TouchableOpacity
            onPress={() => {
              props.netBankingSelected("0038");
              // props.navigation.push("NetBanking", { customize_style: applyCustomHStyle });
            }}
            style={{ alignItems: "center" }}
          >
            <Image
              source={{
                uri: "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/sbi.svg",
              }}
              style={{
                height: Device.deviceType == '1' ? '2rem' : '3.0625rem',
                width: Device.deviceType == '1' ? '2rem' : '3.0625rem',
              }}
            />

            <Text
              style={props.customize_style("header", {
                fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9347rem',
                marginTop: Device.deviceType == '1' ? '1rem' : '2rem',
                fontFamily: "Roboto_400Regular",
                color: "#464646",
              })}
            >
              SBI
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.netBankingSelected("0021");
            }}
            style={{ alignItems: "center" }}
          >
            <Image
              source={{
                uri: "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/icic.svg",
              }}
              style={{
                height: Device.deviceType == '1' ? '2rem' : '3.0625rem',
                width: Device.deviceType == '1' ? '2rem' : '3.0625rem',
              }}
            />
            <Text style={props.customize_style("header", {
              fontFamily: "Roboto_400Regular",
              color: "#464646",
              fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9347rem',
              marginTop: Device.deviceType == '1' ? '1rem' : '2rem',
            })}>ICICI</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.netBankingSelected("0031");
            }}
            style={{ alignItems: "center" }}
          >
            <Image
              source={{
                uri: "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/kotak.svg",
              }}
              style={{
                height: Device.deviceType == '1' ? '2rem' : '3.0625rem',
                width: Device.deviceType == '1' ? '2rem' : '3.0625rem',
              }}
            />

            <Text
              style={props.customize_style("header", {
                fontFamily: "Roboto_400Regular",
                color: "#464646",
                fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9347rem',
                marginTop: Device.deviceType == '1' ? '1rem' : '2rem',
              })}
            >
              Kotak
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.netBankingSelected("0046");
            }}
            style={{ alignItems: "center" }}
          >
            <Image
              source={{
                uri: "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/yesb.png",
              }}
              style={{
                height: Device.deviceType == '1' ? '2rem' : '3.0625rem',
                width: Device.deviceType == '1' ? '2rem' : '3.0625rem',
              }}
            />{" "}
            <Text style={props.customize_style("header", {
              fontFamily: "Roboto_400Regular",
              color: "#464646",
              fontSize: Device.deviceType == '1' ? '0.75rem' : '0.9347rem',
              marginTop: Device.deviceType == '1' ? '1rem' : '2rem',
            })}>Yes Bank</Text>
          </TouchableOpacity>
        </View>
        <View style={{ alignItems: "flex-end" }}>
          <TouchableOpacity
            style={props.customize_style("inverse-button", {
              flexDirection: "row",
              borderRadius: (32 * props.scale) / 440,
              borderWidth: 1,
              paddingVertical: '0.5em',
              paddingHorizontal: '1.62em',
              backgroundColor: "#481380",
              alignItems: "flex-end",
              justifyContent: "center",
            })}
            onPress={() => {
              props.navigation.push("NetBanking Select Bank", {
                customize_style: props.customize_style,
                netBankingSelected: props.netBankingSelected,
                navigation: props.navigation,
                scale: props.scale,
              });
              // props.moreBanksSelected();
            }}>
            <Text
              style={props.customize_style("inverse-button", {
                fontFamily: "Roboto_400Regular",
                color: "#fff",
                fontSize: Device.deviceType == '1' ? '0.625rem' : '1rem',
              })}
            >
              MORE BANKS
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default NetBanking;
