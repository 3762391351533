// const CollectSuccessRedirect = () => {
//     return (

//     );
// }

// export default CollectSuccessRedirect;

import React, { Component, useEffect, useState } from "react";
import { View, Text, ScrollView, StyleSheet, SafeAreaView, Image } from "react-native";
import config from "../../config";
import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { ProzaLibre_400Regular } from "@expo-google-fonts/proza-libre";
import payment_success_light from "../../assets/lottie-animations/payment-success_light.json";
import payment_success_dark from "../../assets/lottie-animations/payment-success_dark.json";
import LottieView from "lottie-react-native";
import Lottie from "lottie-web";
import Logo from "../../assets/images/darkpurple";
import "@dotlottie/player-component";

const CollectSuccessRedirect = (props) => {
  let [fontsLoaded] = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
    ProzaLibre_400Regular,
  });
  const timer_start = 10;
  const [timer, setTimer] = useState(timer_start);

  useEffect(() => {
    setTimeout(() => {
      window.location = props.redirect_url;
    }, timer_start * 1000);
  }, []);

  useEffect(() => {
    setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  }, []);
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#payment-success-lottie-div"),
      animationData: animation,
      loop: false,
    });
  }, []);
  if (props.customize_style("transition_theme").theme == "dark") {
    var animation = payment_success_dark;
    // var animation = "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/payment_processing_light.lottie";
  } else {
    var animation = payment_success_light;
    // var animation = "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/payment_processing_dark.lottie";
  }
  return (
    <SafeAreaView style={props.customize_style("isPaymentCollectionSuccess", { flex: 1, backgroundColor: "#fff", width: "100", height: "100%" })}>
      {/* <form id="myForm" action="https://fancyshop.unaux.com/wc-api/WC_Gateway_Vouch/" hidden method="GET"> */}
      <form id="myForm" hidden method="POST"></form>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <Text
            style={props.customize_style("isPaymentCollectionSuccess", {
              fontFamily: "Roboto_400Regular",
              fontSize: 24,
              textAlign: "center",
              marginTop: 80,
              marginBottom: 20,
            })}
          >
            Awesome! Your payment is successful
          </Text>

          {/* <LottieView options={{ animationData: animation, autoplay: true, loop: false }} width={"60%"} height={"60%"} /> */}
          {/* <LottieView source={animation} autoPlay loop /> */}
          <div id="payment-success-lottie-div" style={{ width: 500, height: 500, alignItems: "center" }} />
          {/* <dotlottie-player autoplay controls loop mode="normal" src={animation} style="width: 320px"></dotlottie-player> */}
          <View style={{ alignItems: "center" }}>
            <Text style={props.customize_style("header", { fontFamily: "Roboto_400Regular", fontSize: 16, color: "#000" })}>
              This is an escrow protected payment
            </Text>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 42, alignItems: "center" }}>
            <View style={{ flex: 1 }}></View>

            {/* <Text style={{fontFamily:'Roboto-Regular',fontSize:16,textAlign:'left'}}>
                We have sent an SMS with an app download link
              </Text> */}
            <Text
              style={props.customize_style("header", {
                fontSize: 18,
                fontFamily: "ProzaLibre_400Regular",
                color: "#000",
                marginRight: 10,
              })}
            >
              Powered by
            </Text>
            <Logo width="40" height="40" />

            <View style={{ flex: 1 }}></View>
          </View>
          {timer >= 0 ? (
            <Text style={props.customize_style("isPaymentCollectionSuccess", { fontFamily: "Roboto_400Regular", textAlign: "center", marginTop: 50 })}>
              Redirecting in {timer} seconds
            </Text>
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  animationSize: { height: 400, aspectRatio: 16 / 9 },
  text: {
    fontFamily: "OpenSans-SemiBold",
    fontSize: 24,
    textAlign: "center",
    color: "#000000",
    lineHeight: 26,
    letterSpacing: 1,
  },
});

export default CollectSuccessRedirect;
