import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from "react-native";
import config from "../config";
import logsService from "../services/logs.service";
import * as Device from 'expo-device';


export default class PrimaryButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  customize_style = (event, style) => {
    return this.props.customize_style(event, style);
  };
  render() {
    let button_color = config.primary_color;
    // if (this.props.theme && this.props.theme.button && this.props.theme.button.backgroundColor) {
    //   button_color = this.props.theme.button.backgroundColor;
    // }
    // var button_fontColor = "#F7F6F9";
    // if (this.props.theme && this.props.theme.button && this.props.theme.button.textColor) {
    //   button_fontColor = this.props.theme.button && this.props.theme.button.textColor;
    // }
    return (
      <View style={{ paddingTop: 6 }}>
        <TouchableOpacity
          disabled={this.props.loading || this.props.disabled ? true : false}
          style={this.customize_style("button", {
            backgroundColor: button_color,
            borderRadius: '2rem',
            padding: Device.deviceType == 1 ? '0.5rem' : '0.7em',
            width: "100%",
          })}
          onPress={() => {
            logsService.logCustomEvent(`${this.props.content.replace(/ /g, "_").toLowerCase()}_button_clicked`, {});
            this.props.clicked();
          }}
        >
          {this.props.loading ? (
            <ActivityIndicator size="small" color="#fff" />
          ) : (
            <Text
              style={this.customize_style("button", {
                color: "#fff",
                fontSize: Device.deviceType == 1 ? '0.875rem' : '1rem' ,
                fontFamily: "Roboto_400Regular",
                textAlign: "center",
                textTransform:'none'
              })}
            >
              {" "}
              {this.props.content}
            </Text>
          )}
        </TouchableOpacity>
        {this.props?.helperText}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: config.primary_color,
    padding: 13,
    borderRadius: 25,
    marginTop: 25,
    zIndex: 100,
  },
});
