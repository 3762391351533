export default {
  primary_color: "#481380",
  dull_color: "#545454",
  danger: "#DB235B",
  colors: ["#DB235B", "#473BB3", "#09C98F", "#5956FE", "#6E23DB", "#481380", "#DEAA01"],
  senderID: "776737643024",
  width: 600,

  // Local
  // node_api_endpoint: "https://devcollect.iamvouched.com",
  // node_api_endpoint: "https://testsim.iamvouched.com",
  // node_api_endpoint: "http://192.168.1.96:6000",
  // chat_server: "ws://192.168.31.202:3000",

  // // Test
  // node_api_endpoint: "https://test.api.iamvouched.com/v1",
  // chat_server: "https://test.api.iamvouched.com",
  // env:'sandbox',
  // firebase: {
  //   apiKey: "AIzaSyDXQMcUBRivwPhlPcSRFdNlEPB4N_m2Bqg",
  //   authDomain: "vouch-testz.firebaseapp.com",
  //   databaseURL: "https://vouch-testz.firebaseio.com",
  //   projectId: "vouch-testz",
  //   storageBucket: "vouch-testz.appspot.com",
  //   messagingSenderId: "923127562999",
  //   appId: "1:923127562999:web:d7175c87493b2caabb09f2",
  //   measurementId: "G-M756V1G69V",
  // },
  // Prod
  node_api_endpoint: "https://escrow.iamvouched.com",
  // chat_server:'https://prod.api.iamvouched.com',
  env: "prod",
  firebase: {
    apiKey: "AIzaSyDeBwHNDjQRxKJj-GYKd9tOeF0QsKoZZ4Y",
    authDomain: "vout-263411.firebaseapp.com",
    databaseURL: "https://vout-263411.firebaseio.com",
    projectId: "vout-263411",
    storageBucket: "vout-263411.appspot.com",
    messagingSenderId: "776737643024",
    appId: "1:776737643024:web:8fbb8c2b43be5aa843c895",
    measurementId: "G-F24G6QX424",
  },
};
