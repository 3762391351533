import React, { useEffect, useState } from "react";
import { View, Text, Modal, TouchableOpacity } from "react-native";
import config from "../../config";
import Decimal from "decimal.js";
import * as Device from 'expo-device';

const CardPayment = (props) => {
  var [convenience_fees, setConvenienceFees] = useState(0);
  var [total_amount, setTotalAmount] = useState(0);

  if (
    props.order_details.hasOwnProperty("convenience_fees") &&
    props.order_details.convenience_fees.hasOwnProperty("DC") &&
    props.order_details.convenience_fees.DC != 0 &&
    total_amount == 0
  ) {
    console.log("convenience fees : ".convenience_fees);
    if (props.order_details.convenience_fees.DC != 0) {
      setConvenienceFees("(Including " + ((props.order_details.convenience_fees.DC / props.order_details.amount) * 100).toFixed(2) + "% fee)");
      setTotalAmount(Number(Decimal(Number(props.order_details.amount)).add(Number(props.order_details.convenience_fees.DC))));
    }
  } else if (total_amount == 0) {
    setConvenienceFees("(Including 0% fee)");
    setTotalAmount(Number(Decimal(Number(props.order_details.amount))));
  }
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <View
        style={props.customize_style("card", {
          borderRadius: '0.625rem',
          borderWidth: 1,
          borderColor: "#DDDDDD",
          paddingHorizontal: Device.deviceType == '1' ? '1rem' : '2rem',
          paddingVertical: Device.deviceType == '1' ? '1rem' : '1rem',
        })}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
              fontFamily: "Roboto_400Regular",
              color: "#000",
              marginRight: '2rem'
            })}
          >
            You will Pay ₹{!Number.isNaN(Number(total_amount)) ? Number(total_amount).toLocaleString() : total_amount}
          </Text>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.625rem' : '0.875rem',
              fontFamily: "Roboto_400Regular",
              color: "#737373",
            })}
          >
            {convenience_fees}
          </Text>
        </View>
        <View style={{
          flexDirection: "row", justifyContent: "space-between", alignItems: 'center',
          paddingTop: Device.deviceType == '1' ? '1rem' : '0.62rem',

        }}>
          <View
            style={{
              alignItems: "flex-start",
              width: "50%",
            }}
          >
            <Text style={props.customize_style("header", {
              fontSize: Device.deviceType == '1' ? '0.625rem' : '1.5rem',
              fontFamily: "Roboto_400Regular", color: "#$000"
            })}>
              Debit Card Payment
            </Text>
          </View>
          <View
            style={{
              paddingBottom: Device.deviceType == '1' ? '0rem' : '0.62rem'
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.cardSelected();
                props.navigation.push("DebitCard Details", { customize_style: props.customize_style, scale: props.scale, cardType: "DC" });
              }}
              style={props.customize_style("button", {
                borderRadius: (32 * props.scale) / 440,
                backgroundColor: "#481380",
                paddingVertical: '0.5em',
                paddingHorizontal: '1.62em',
              })}
            >
              <Text style={props.customize_style("button", {
                fontSize: Device.deviceType == '1' ? '0.625rem' : '1rem',
                color: "#fff"
              })}>
                PAY
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

export default CardPayment;
