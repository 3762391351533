// Packages imports
import React, { useEffect, useState } from "react";
import { Modal, SafeAreaView, View, ScrollView, Text, ActivityIndicator, StyleSheet, Image } from "react-native";

import { Dimensions } from "react-native";
// Project module imports
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import UpiOption from "../../components/custom_checkout/UpiOption";
import Header from "../../components/Header";
import AddUpi from "../../components/custom_checkout/AddUpi";
import MoreBanksOverlay from "../../components/custom_checkout/MoreBanksOverlay";
import CardDetailsOverlay from "../../components/custom_checkout/CardDetailsOverlay";

import config from "../../config";
import miscService from "../../services/misc.service";
import CollectSuccessRedirect from "../../components/Marketplace/CollectSuccessRedirect";
import ShowError from "../../components/custom_checkout/showError";
import NetBanking from "../../components/custom_checkout/NetBanking";
import CardPayment from "../../components/custom_checkout/CardPayment";
import CreditCardPayment from "../../components/custom_checkout/CreditCardPayment";
import Logo from "../../assets/images/darkpurple";
import VouchUPITimer from "../../components/custom_checkout/VouchUPITimer";
import VouchQR from "../../components/custom_checkout/UPIQR";
import BankTransferOption from "../../components/custom_checkout/BankTransferOption";
import paymentServices from "../../services/payments.service";
// Helper component for all the modals(overlays)
import Lottie from "lottie-web";
import { useFonts, ProzaLibre_400Regular } from "@expo-google-fonts/proza-libre";
import * as Device from 'expo-device';



const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const data = urlParams.get("data");

const get_width = (wdt) => {
  if (Device.deviceType == 1) return wdt
  else {
    if (wdt >= 1360) {
      return 0.45 * wdt
    } else {
      return 0.55 * wdt
    }
  }
}

const GuestPaymentCheckout = (props) => {
  let [fontsLoaded] = useFonts({
    ProzaLibre_400Regular,
  });
  const [order_details, setOrderDetails] = useState({});
  // const isMobileDevice = Constants.deviceType === Constants.deviceTypes.PHONE;
  // const isMobileDevice = Constants.deviceType === Constants.deviceTypes.PHONE;

  const [addUpi, setAddUpi] = useState(false);
  const [moreBanks, setMoreBanks] = useState(false);
  const [cardOverlay, setCardOverlay] = useState(false);
  const [creditCardOverlay, setCreditCardOverlay] = useState(false);
  const [payment_collect_detials, setPaymentCollectDetials] = useState({});
  const [windowSize, setWindowSize] = useState(get_width(Dimensions.get("window").width));
  const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);
  const [vouchUPIOptions, setVouchUPIOptions] = useState(null);
  const [showUPITimer, setUPITimer] = useState(false);
  const [showUPIQR, setUPIQR] = useState(false);
  const [error_message, setErrorMessage] = useState("");

  const [dcErrors, setDCErrors] = useState({});

  const [loaders, setLoaders] = useState({
    isFetching: true,
    isPaying: false,
    isPaymentCollectionSuccess: false,
    isError: false,
  });

  const { isFetching, isError, isPaying, isPaymentCollectionSuccess } = loaders;
  // const order_details = order_details;



  // useEffect(()=>{
  //   console.log('====================================');
  //   console.log(Device.deviceType);
  //   console.log(Device.deviceName);
  //   console.log(Device.DeviceType);
  //   console.log('====================================');
  // },[])

  const ModalWrapper = (props) => (
    // <Provider>
    //   <Portal>
    <Modal
      animationType="slide"
      transparent={true}
      visible={props.visible}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        dismissHandler();
      }}
    >
      <View
        style={{
          width: "100%",
          height: "100%",

          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // marginTop: 22,
        }}
      >
        {props.children}
      </View>
    </Modal>
    //   </Portal>
    // </Provider>
  );
  // const amount_with_payment_charges = Math.ceil(parseInt(order_details?.amount) + (2 / 100) * parseInt(order_details?.amount));

  const addScript = ({ src, id, onLoad }) => {
    const existing = document.getElementById(id);
    if (existing) {
      return existing;
    } else {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        if (onLoad) {
          onLoad();
        }
      };
      document.body.appendChild(script);
      return script;
    }
  };
  const [width, setWidth] = useState(Dimensions.get("window").width);
  const [routeStyle, setRouteStyle] = useState({});
  // this block runs only once and when the page loads
  const fetch_order_details = (data) => {
    if (!data) {
      setLoaders({ isError: true });
      return;
    }
    if (data == "error") {
      setLoaders({ isError: true });
      return;
    }
    if (data != "error" && Object.keys(order_details).length == 0) {
      paymentServices
        .fetch_order_for_guest_payment({
          data: data,
        })
        .then((result) => {
          result.link = data;
          if (result.redirect_url.substring(result.redirect_url.length - 1) != "/") {
            result.redirect_url += "/";
          }
          // set_order_data(order_details);
          setOrderDetails(result);

          // if (result.theme && result.theme.backgroundColor) props.setParentBackgroundColor(result.theme.backgroundColor);
          if (["awaiting settlement", "settled", "completed"].includes(result.status)) {
            setLoaders({ isPaying: false, isPaymentCollectionSuccess: true });
          } else if (result.status == "failed") {
            // if (result.hasOwnProperty("code") && result.code === "ERR_BL_NDCU") {
            //   props.navigation.push("CardDetailsOverlay", { customize_style: customize_style });
            //   return;
            // }

            setLoaders({ isError: true });
            if (result.hasOwnProperty("message") && result.message) {
              setErrorMessage(result.message);
            } else {
              setErrorMessage("Your Payment has Failed. Please Try againt");
            }
          } else if (result.status === "declined") {
            setLoaders({ isError: true });
            setErrorMessage("The Payment has failed as Payer has declined the Payment Request. Please Try again !!");
          } else {
            setLoaders((loaders) => ({ ...loaders, isFetching: false }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const setViewSize = () => {
    var w = Dimensions.get("window").width;
    // if (w >= 600) {
    //   setWindowSize(w);
    //   // setRouteStyle({ width: "40%", height: "100%", marginLeft: "30%" });
    //   setRouteStyle({});
    // } else {
    setWindowSize(w);
    setRouteStyle({});
    // }
  };

  useEffect(() => {
    fetch_order_details(data);
    // init_order_details();
    const script = addScript({
      src: "https://spg-prod-cdn.freecharge.in/dist/main.js",
      id: "freecharge_main",
      onLoad: () => {
        console.log("Freecharge Script Loaded");
      },
    });

    const resizeListener = Dimensions.addEventListener("change", () => {
      var w = Dimensions.get("window").width;

      // if (w >= 600) {
      //   setWindowSize(600);
      //   setRouteStyle({ width: "40%", height: "100%", marginLeft: "30%" });
      // } else {
      setWindowSize(w);
      setRouteStyle({});
      // }
    });
    return () => resizeListener?.remove();

    console.log("done loading script");
  }, []);
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#payment-error-lottie-div"),
      animationData: failedAnim,
      loop: true,
      autoplay: true,
    });
  }, []);

  const applyCustomStyle = (property, style) => {
    switch (property) {
      case "header":
        if (order_details.theme && order_details.theme.card && order_details.theme.card.header && order_details.theme.card.header.fontColor) {
          style.color = order_details.theme.card.header.fontColor;
        }
        break;
      case "subText":
        if (order_details.theme && order_details.theme.card && order_details.theme.card.subText && order_details.theme.card.subText.fontColor) {
          style.color = order_details.theme.card.subText.fontColor;
        }
        break;
      case "card":
        if (order_details.theme && order_details.theme.card && order_details.theme.card.borderColor) {
          style.borderColor = order_details.theme.card.borderColor;
        }
        if (order_details.theme && order_details.theme.card && order_details.theme.card.backgroundColor) {
          style.backgroundColor = order_details.theme.card.backgroundColor;
        }

        break;
      case "inverse-button":
        if (order_details.theme && order_details.theme.button) {
          if (style.hasOwnProperty("backgroundColor")) {
            style.backgroundColor = order_details.theme.backgroundColor;
          }
          if (style.hasOwnProperty("color")) {
            style.color = order_details.theme.button.backgroundColor;
          }
          if (order_details.theme && order_details.theme.button) {
            style.borderColor = order_details.theme.button.backgroundColor;
          }
        }
        break;
      case "button":
        if (order_details.theme && order_details.theme.button) {
          if (style.hasOwnProperty("backgroundColor")) {
            style.backgroundColor = order_details.theme.button.backgroundColor;
          }
          if (style.hasOwnProperty("color")) {
            style.color = order_details.theme.button.textColor;
          }
        }
        break;
      case "background":
        if (order_details && order_details.theme && order_details.theme.backgroundColor) {
          style.backgroundColor = order_details.theme.backgroundColor;
        }
        break;
      case "isPaymentCollectionSuccess":
      case "isPaying":
      case "isError":
        if (order_details.theme && order_details.theme.transition && order_details.theme.transition.backgroundColor) {
          style.backgroundColor = order_details.theme.transition.backgroundColor;
        }
        if (order_details.theme && order_details.theme.transition && order_details.theme.transition.header && order_details.theme.transition.header.fontColor) {
          style.color = order_details.theme.transition.header.fontColor;
        }
        break;
      case "PaymentCollectionSuccessImages":
        if (order_details.theme && order_details.theme.transition && order_details.theme.transition.is_payment_success_url) {
          return order_details.theme.transition.is_payment_success_url;
        }
        break;
      case "isPayingImages":
        if (order_details.theme && order_details.theme.transition && order_details.theme.transition.is_paying_image_url) {
          return order_details.theme.transition.is_paying_image_url;
        }
        break;
      case "isErrorImages":
        if (order_details.theme && order_details.theme.transition && order_details.theme.transition.is_error_image_url) {
          return order_details.theme.transition.is_error_image_url;
        }
        break;
      case "transition_theme":
        if (order_details.theme && order_details.theme.transition && order_details.theme.transition) {
          return order_details.theme.transition;
        }
        break;

      case "banner":
        // var url =
        //   order_details && order_details.theme && order_details.theme.logo
        //     ? order_details.theme.logo
        //     : "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/Vouch.jpeg";
        // var ImageBackgroundColor =
        //   order_details && order_details.theme && order_details.theme.banner && order_details.theme.banner.imageBackgroundColor
        //     ? order_details.theme.banner.imageBackgroundColor
        //     : "#fff";
        var bannerBackgroundColor =
          order_details && order_details.theme && order_details.theme.banner && order_details.theme.banner.backgroundColor
            ? order_details.theme.banner.backgroundColor
            : "#481380";
        var fontColor =
          order_details && order_details.theme && order_details.theme.banner && order_details.theme.banner.fontColor
            ? order_details.theme.banner.fontColor
            : "#fff";
        style.backgroundColor = bannerBackgroundColor;
        style.color = fontColor;
        break;
    }
    return style;
  };

  // this block fetches the order details from the server
  const init_order_details = () => {
    if (!data) {
      setLoaders({ isError: true });
      return;
    }
    if (data == "error") {
      setLoaders({ isError: true });
      return;
    }

    if (Object.keys(order_details).length != 0) {
      order_details.link = data;
      if (order_details.redirect_url.substring(order_details.redirect_url.length - 1) != "/") {
        order_details.redirect_url += "/";
      }
      // set_order_data(order_details);
      if (order_details.theme && order_details.theme.backgroundColor) props.setParentBackgroundColor(order_details.theme.backgroundColor);
      if (["awaiting settlement", "settled", "completed"].includes(order_details.status)) {
        setLoaders({ isPaying: false, isPaymentCollectionSuccess: true });
      } else if (order_details.status == "failed") {
        setLoaders({ isError: true });
        setErrorMessage("Your Payment has Failed. Please Try againt");
      } else {
        setLoaders((loaders) => ({ ...loaders, isFetching: false }));
      }
    }
  };
  // overlay for upi overlay
  const upiModal = () => (
    <ModalWrapper
      visible={addUpi}
      dismissHandler={() => {
        setAddUpi(false);
      }}
    >
      <AddUpi
        onClose={(data) => {
          setAddUpi(false);
        }}
        style={props.style}
        customize_style={applyCustomStyle}
        onSuccess={async (data) => {
          setAddUpi(false);
          // initPaymentCheckout({ method: "UPI", vpa: data });
          setIsMakingUpiPayment(true);
          try {
            var payload = await initiateVouchUpiCollect({ vpa: data });
            console.log("Init Upi Payment");
            console.log(payload);
            if (payload.status != "initiated") {
              setErrorMessage(payload.message);
              setLoaders((loaders) => ({ ...loaders, isError: true }));
            }
          } catch (err) {
            setErrorMessage(err.message);
            setLoaders((loaders) => ({ ...loaders, isError: true }));
          }
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  // helper for Net banking overlay
  const netBankingModal = () => (
    <ModalWrapper
      visible={moreBanks}
      dismissHandler={() => {
        setMoreBanks(false);
      }}
    >
      <MoreBanksOverlay
        comeBack={(data) => {
          setMoreBanks(false);
        }}
        customize_style={applyCustomStyle}
        onBankSelected={(data) => {
          setMoreBanks(false);
          initiate_net_banking_checkout({
            method: "NETBANKING",
            bank: data,
          });
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  const CreditCardDetailsOverlay = () => (
    <ModalWrapper
      visible={creditCardOverlay}
      dismissHandler={() => {
        setCreditCardOverlay(false);
      }}
    >
      {hidden_div()}
      <CardDetailsOverlay
        fg_errors={dcErrors}
        comeBack={(data) => {
          setCreditCardOverlay(false);
          setDCErrors({});
        }}
        customize_style={applyCustomStyle}
        onSuccess={(data) => {
          initiate_card_checkout(data, "CC");
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  // helper for card details Overlay
  const cardDetailsOverlay = () => (
    <ModalWrapper
      visible={cardOverlay}
      dismissHandler={() => {
        setCardOverlay(false);
      }}
      style={props.style}
    >
      {hidden_div()}
      <CardDetailsOverlay
        fg_errors={dcErrors}
        comeBack={(data) => {
          setCardOverlay(false);
          setDCErrors({});
        }}
        customize_style={applyCustomStyle}
        onSuccess={(data) => {
          initiate_card_checkout(data);
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  const initiate_card_checkout = async (user_payment_detials, mode = "DC") => {
    // setLoaders((loaders) => ({ ...loaders, isPaying: true }));

    var card = {};
    card["nameOnCard"] = user_payment_detials.nameOnCard;
    card["cardNumber"] = user_payment_detials.cardNumber;
    card["cvv"] = user_payment_detials.cvv;
    card["expiryMonth"] = user_payment_detials.expiry.slice(0, 2);
    card["expiryYear"] = user_payment_detials.expiry.slice(-4);
    try {
      const payload = await miscService.get_freecharge_netbanking_link({ data: data, bank_id: "na", mode: mode, card: card });
      console.log("freecharge link");
      console.log(payload);
      if (payload.data && payload.data.code && payload.data.code.startsWith("ERR_")) {
        setLoaders({ isError: true });
        setErrorMessage(payload.message);
        return;
      }

      try {
        var form = document.getElementById("payment_form");
        // form.action = payload.action;
        var form_attr = { ...payload };
        delete form_attr.action;
        var keys = Object.keys(form_attr);

        for (var i = 0; i < keys.length; i++) {
          switch (keys[i]) {
            case "subMode":
              var bankSelect = document.createElement("select");
              bankSelect.setAttribute("name", "bankSelect");
              bankSelect.setAttribute("class", "bankSelect");
              form.appendChild(bankSelect);
              var bankOption = document.createElement("option");
              bankOption.setAttribute("value", form_attr[keys[i]]);
              // bankOption.setAttribute("selected", true);

              bankOption.text = form_attr[keys[i]];
              bankSelect.appendChild(bankOption);
              bankSelect.value = form_attr[keys[i]];
              break;
            case "txnMode":
              var input = document.createElement("input");
              input.setAttribute("class", "paymentType");
              input.setAttribute("value", form_attr[keys[i]]);
              input.setAttribute("type", "hidden");
              form.appendChild(input);
              break;
            case "currency":
            case "env":
              break;

            default:
              var input = document.createElement("input");
              input.setAttribute("name", keys[i]);
              input.setAttribute("value", form_attr[keys[i]]);
              input.setAttribute("class", keys[i]);
              input.setAttribute("type", "hidden");
              form.appendChild(input);
          }
        }
        var submitButton = document.createElement("input");
        submitButton.setAttribute("class", "make_payment");
        submitButton.setAttribute("type", "submit");
        submitButton.style.visibility = false;
        form.appendChild(submitButton);
        var element = document.getElementById("pay-nb");

        console.log(element.innerHTML);
        FC.SetPay({
          paymentForm: "payment_form",
          enviroment: form_attr["env"],
          // two enviroment possible SANDBOX or PROD
          inputCallback: (event) => {
            console.log(event);
          },
          errorHandler: async (error) => {
            setLoaders((loaders) => ({ ...loaders, isPaying: false }));
            var msg = [];
            var fg_errors = {};
            for (var i = 0; i < error.length; i++) {
              switch (error[i].msg) {
                case "Invalid card number":
                  fg_errors.cardNumber = error[i].msg;
                  break;
                case "Invalid date":
                  fg_errors.expiry = error[i].msg;
                  break;
                case "Invalid CVV":
                  fg_errors.cvv = error[i].msg;
                  break;
                case "Invalid Name on Card":
                  fg_errors.nameOnCard = error[i].msg;
                  break;
              }
            }

            setDCErrors(fg_errors);
            console.log(error);
          },
        });
        submitButton.click();
        // window.location=return_url;
      } catch (err) {
        setIsMakingUpiPayment(false);
        console.log(err);
        throw err;
      }
    } catch (error) {
      setLoaders((loaders) => ({ ...loaders, isError: true, isPaying: false }));
    }
  };
  // Payment Handler for NETBANKING Mode
  const initiate_net_banking_checkout = async (user_payment_detials) => {
    setLoaders((loaders) => ({ ...loaders, isPaying: true }));

    //get redirect url for freecharge

    try {
      const payload = await miscService.get_freecharge_netbanking_link({ data: data, bank_id: user_payment_detials.bank, mode: "NB" });
      console.log("freecharge link");
      console.log(payload);
      if (payload.data && payload.data.code && payload.data.code.startsWith("ERR_")) {
        setLoaders({ isError: true });
        setErrorMessage(payload.message);
        return;
      }

      try {
        var form = document.getElementById("payment_form");
        // form.action = payload.action;
        var form_attr = { ...payload };
        delete form_attr.action;
        var keys = Object.keys(form_attr);

        for (var i = 0; i < keys.length; i++) {
          switch (keys[i]) {
            case "subMode":
              var bankSelect = document.createElement("select");
              bankSelect.setAttribute("name", "bankSelect");
              bankSelect.setAttribute("class", "bankSelect");
              form.appendChild(bankSelect);
              var bankOption = document.createElement("option");
              bankOption.setAttribute("value", form_attr[keys[i]]);
              // bankOption.setAttribute("selected", true);

              bankOption.text = form_attr[keys[i]];
              bankSelect.appendChild(bankOption);
              bankSelect.value = form_attr[keys[i]];
              break;
            case "txnMode":
              var input = document.createElement("input");
              input.setAttribute("class", "paymentType");
              input.setAttribute("value", form_attr[keys[i]]);
              input.setAttribute("type", "hidden");
              form.appendChild(input);
              break;
            case "currency":
            case "env":
              break;

            default:
              var input = document.createElement("input");
              input.setAttribute("name", keys[i]);
              input.setAttribute("value", form_attr[keys[i]]);
              input.setAttribute("class", keys[i]);
              input.setAttribute("type", "hidden");
              form.appendChild(input);
          }
        }
        var submitButton = document.createElement("input");
        submitButton.setAttribute("class", "make_payment");
        submitButton.setAttribute("type", "submit");
        submitButton.style.visibility = false;
        form.appendChild(submitButton);
        var element = document.getElementById("pay-nb");

        console.log(element.innerHTML);
        FC.SetPay({
          paymentForm: "payment_form",
          enviroment: form_attr["env"],
          // two enviroment possible SANDBOX or PROD
          inputCallback: (event) => {
            console.log(event);
          },
          errorHandler: (error) => {
            window.freecharge_error = error;
            console.log(error);
          },
        });
        submitButton.click();
        // window.location=return_url;
      } catch (err) {
        setIsMakingUpiPayment(false);
        console.log(err);
        throw err;
      }
    } catch (error) {
      setLoaders((loaders) => ({ ...loaders, isError: true, isPaying: false }));
    }
  };

  const initiateVouchUpiCollect = async ({ vpa }) => {
    try {
      const payload = await miscService.instantiate_vouch_UPI_collect({
        data: data,
        vpa: vpa,
        // options: options,
      });
      console.log("upi_payload" + payload);
      if (payload.status == "initiated") {
        // setVouchUPIOptions(options);
        setVouchUPIOptions({});
        setUPITimer(true);
      } else {
        setIsMakingUpiPayment(false);
      }
      return payload;
    } catch (error) {
      throw error;
    }
  };

  // Show this when he order is been fetched from the server
  if (isFetching)
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );

  // Show this when there is an error in the payment
  if (isError) {
    return (
      <View
        style={applyCustomStyle("background", {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
          paddingHorizontal: 20,
          backgroundColor: "#FFF",
        })}
      >
        <ShowError order_details={order_details} customize_style={applyCustomStyle} error_message={error_message} scale={windowSize} />;
      </View>
    );
  }
  const hidden_div = () => (
    <>
      <div id="pay-nb" hidden>
        <form id="payment_form"></form>
      </div>
      <button id="pay-nb-btn" hidden>
        Pay
      </button>
    </>
  );

  // when we are collection money show this loade
  if (isPaying) {
    var transition_theme = applyCustomStyle("transition_theme");
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
          paddingHorizontal: 20,
          backgroundColor: transition_theme.backgroundColor ? transition_theme.backgroundColor : "#FFF",
        }}
      >
        {hidden_div()}
        <View style={{ alignItems: "center" }}>
          {/* <LottieView options={{ animationData: loadingAnim, autoplay: true, loop: true }} /> */}
          <Image
            source={"https://b2bimages-1122.s3.ap-south-1.amazonaws.com/protect_payments.png"}
            style={{ width: (250 * windowSize) / 440, height: (250 * 1.17 * windowSize) / 440, padding: 30 }}
          />
        </View>
        <Text
          style={applyCustomStyle("header", {
            padding: (30 * windowSize) / 440,
            fontColor: "#FFF",
            fontSize: (18 * windowSize) / 440,
            fontFamily: "Roboto_400Regular",
          })}
        >
          Protecting your payment
        </Text>
      </View>
    );
  }
  // when we are collection money show this loade
  if (isPaymentCollectionSuccess)
    return <CollectSuccessRedirect customize_style={applyCustomStyle} redirect_url={order_details.redirect_url + "?data=" + order_details.link} />;

  // when we are collection money show this loade
  if (showUPITimer)
    return (
      <View
        style={applyCustomStyle("background", {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
          paddingHorizontal: 20,
          backgroundColor: "#FFF",
        })}
      >
        {/* {hidden_div()} */}
        <VouchUPITimer
          customize_style={applyCustomStyle}
          onCollectFailed={(errorMsg) => {
            setErrorMessage(errorMsg);
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isError: true,
            }));
            setUPITimer(false);
            setVouchUPIOptions(null);
            setIsMakingUpiPayment(false);
          }}
          onCollectSuccess={async (success_payload) => {
            // let redirect_details = await paymentServices.update_guest_payment_order_payment_detials({
            //   order_id: data,
            //   payment_details: {
            //     ...success_payload,
            //     mode: "upi",
            //   },
            // });
            success_payload.redirect_url = order_details.redirect_url;
            setPaymentCollectDetials(success_payload);
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isPaymentCollectionSuccess: true,
            }));
            setUPITimer(false);
            setIsMakingUpiPayment(false);
            setVouchUPIOptions(null);
          }}
          // collect_ref={vouchUPIOptions.collects[0].collect_ref}
          // order_id={data}
          data={data}
        />
      </View>
    );



  // when we are collection money show this loade
  if (showUPITimer)
    return (
      <View
        style={applyCustomStyle("background", {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
          paddingHorizontal: 20,
          backgroundColor: "#FFF",
        })}
      >
        {/* {hidden_div()} */}
        <VouchUPITimer
          customize_style={applyCustomStyle}
          onCollectFailed={(errorMsg) => {
            setErrorMessage(errorMsg);
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isError: true,
            }));
            setUPITimer(false);
            setVouchUPIOptions(null);
            setIsMakingUpiPayment(false);
          }}
          onCollectSuccess={async (success_payload) => {
            // let redirect_details = await paymentServices.update_guest_payment_order_payment_detials({
            //   order_id: data,
            //   payment_details: {
            //     ...success_payload,
            //     mode: "upi",
            //   },
            // });
            success_payload.redirect_url = order_details.redirect_url;
            setPaymentCollectDetials(success_payload);
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isPaymentCollectionSuccess: true,
            }));
            setUPITimer(false);
            setIsMakingUpiPayment(false);
            setVouchUPIOptions(null);
          }}
          // collect_ref={vouchUPIOptions.collects[0].collect_ref}
          // order_id={data}
          data={data}
        />
      </View>
    );


      // when we are collection money show this loade
  if (showUPIQR)
  return (
    <View
      style={applyCustomStyle("background", {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        paddingHorizontal: 20,
        backgroundColor: "#FFF",
      })}
    >
      {/* {hidden_div()} */}
      <VouchQR
        customize_style={applyCustomStyle}
        scale={windowSize}
        onCollectFailed={(errorMsg) => {
          setErrorMessage(errorMsg);
          setLoaders((loaders) => ({
            ...loaders,
            isPaying: false,
            isError: true,
          }));
          setUPITimer(false);
          setVouchUPIOptions(null);
          setIsMakingUpiPayment(false);
        }}
        onCollectSuccess={async (success_payload) => {
          // let redirect_details = await paymentServices.update_guest_payment_order_payment_detials({
          //   order_id: data,
          //   payment_details: {
          //     ...success_payload,
          //     mode: "upi",
          //   },
          // });
          success_payload.redirect_url = order_details.redirect_url;
          setPaymentCollectDetials(success_payload);
          setLoaders((loaders) => ({
            ...loaders,
            isPaying: false,
            isPaymentCollectionSuccess: true,
          }));
          setUPITimer(false);
          setIsMakingUpiPayment(false);
          setVouchUPIOptions(null);
        }}
        onClose={()=>{
          setUPIQR(false)
        }}
        // collect_ref={vouchUPIOptions.collects[0].collect_ref}
        // order_id={data}
        data={data}
      />
    </View>
  );


  // if (Platform.OS == "web") {
  //   if (width > 990) {
  //     setRouteStyle({ width: "40%", height: "100%", marginLeft: "30%" });
  //   }
  // }
  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
        width: "100%",
        hieght: "100%",
        // ...routeStyle,
      }}
    >
      {hidden_div()}

      <ScrollView
        style={{ width: "100%", paddingHorizontal: (16 * windowSize) / 440 }}
        contentContainerStyle={{ alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
            position: "absolute",
            // ...routeStyle,
            height: "100%",
            zIndex: 9999,
            display: isMakingUpiPayment ? "flex" : "none",
          }}
        >
          <ActivityIndicator size="large" color={config.primary_color} />
        </View>
        <View style={{ width: "100vw", alignItems: "center", justifyContent: "center" }}>
          <View style={{
            width: windowSize,
            minHeight: '100svh',
            paddingHorizontal: [1, 2].includes(Device.deviceType) ? '1rem' : '4rem',
            paddingVertical: [1, 2].includes(Device.deviceType) ? '1rem' : '2rem',
            elevation: 5, // Add elevation for box shadow on Android
            shadowColor: 'black', // Specify shadow color for iOS
            shadowOffset: { width: 0, height: 2 }, // Specify shadow offset for iOS
            shadowOpacity: 0.2, // Specify shadow opacity for iOS
            shadowRadius: 4, // Specify shadow radius for iOS,
            fontFamily: "Roboto-Bold"
          }}>
            <Header order_details={order_details} amount={order_details?.amount} color="#FFF" />
            <View style={{ height: '1.5rem' }}></View>

            {order_details?.payment_modes?.includes("UPI") && (
              <UpiOption
                amount={order_details?.amount}
                order_details={order_details}
                customize_style={applyCustomStyle}
                addUpiPressed={() => {
                  setAddUpi(true);
                }}
                navigation={props.navigation}
                setUPITimer={setUPITimer}
                qr_handler={()=>{setUPIQR(true)}}
                saved={[]}
                proceed={(data) => { }}
                showNetBanking={false}
                color={order_details.theme.color_code}
              />
            )}

            <View style={{ height: Device.deviceType == 1 ? '1.62rem' : '2.62rem' }}></View>


            {order_details?.payment_modes?.includes("VIRTUAL_ACCOUNT") && (
            <BankTransferOption
              order_details={order_details}
              customize_style={applyCustomStyle}
              amount={order_details.amount}
              color={order_details.theme.color_code}
              van={order_details.virtual_account_no}
              ifsc={order_details.ifsc}
              createAccount={() => {
                if (order_details.amount >= 20000) {
                  props.createVirtualAccount(order_details);
                }
              }}
              scale={windowSize}
            />
             )}

            <View style={{ height: Device.deviceType == 1 ? '1.62rem' : '2.62rem' }}></View>

            {order_details?.payment_modes?.includes("NET_BANKING") && order_details.amount >= order_details.nb_min_transaction_amount && (
            <NetBanking
              color={order_details.theme.color_code}
              order_details={order_details}
              customize_style={applyCustomStyle}
              moreBanksSelected={() => {
                setMoreBanks(true);
              }}
              navigation={props.navigation}
              netBankingSelected={(data) => {
                initiate_net_banking_checkout({
                  method: "NETBANKING",
                  bank: data,
                });
              }}
              scale={windowSize}
            />
            )}


            <View style={{ height: Device.deviceType == 1 ? '1.62rem' : '2.62rem' }}></View>


            {order_details?.payment_modes?.includes("CREDIT_CARD") && (
            <CreditCardPayment
              color={order_details.theme.color_code}
              navigation={props.navigation}
              customize_style={applyCustomStyle}
              order_details={order_details}
              cardSelected={() => {
                setCreditCardOverlay(true);
              }}
              scale={windowSize}
            />
            )}


            <View style={{ height: Device.deviceType == 1 ? '1.62rem' : '2.62rem' }}></View>



            {order_details?.payment_modes?.includes("DEBIT_CARD") && (
            <CardPayment
              color={order_details.theme.color_code}
              customize_style={applyCustomStyle}
              order_details={order_details}
              navigation={props.navigation}
              cardSelected={() => {
                setCardOverlay(true);
              }}
              scale={windowSize}
            />
            )}


            <View style={{ height: Device.deviceType == 1 ? '1.62rem' : '2.62rem' }}></View>


            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingBottom: 30,
              }}
            >
              <Text
                style={applyCustomStyle("header", {
                  fontSize: 18,
                  fontFamily: "ProzaLibre_400Regular",
                  color: "#000",
                  marginRight: 10,
                })}
              >
                Powered by
              </Text>
              <Logo width="40" height="40" />
            </View>


          </View>
          {/* <View>
            <View
              style={{
                paddingTop: 32,
                width: "100%",
              }}
            >

              {order_details?.payment_modes?.includes("DEBIT_CARD") && (
                <CardPayment
                  color={order_details.theme.color_code}
                  customize_style={applyCustomStyle}
                  order_details={order_details}
                  navigation={props.navigation}
                  cardSelected={() => {
                    setCardOverlay(true);
                  }}
                  scale={windowSize}
                />
              )}
            </View>
            <View style={{ height: 40 }}></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingBottom: 30,
              }}
            >
              <Text
                style={applyCustomStyle("header", {
                  fontSize: 18,
                  fontFamily: "ProzaLibre_400Regular",
                  color: "#000",
                  marginRight: 10,
                })}
              >
                Powered by
              </Text>
              <Logo width="40" height="40" />
            </View>
          </View> */}
        </View>
      </ScrollView>
      {upiModal()}
      {netBankingModal()}
      {CreditCardDetailsOverlay()}
      {cardDetailsOverlay()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    color: config.primary_color,
    padding: 20,
  },
  animationSize: {
    width: 300,
    height: 300,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: order_details && order_details.theme && order_details.theme.backgroundColor ? order_details.theme.backgroundColor : "#FFF",
    paddingHorizontal: 20,
    backgroundColor: "#FFF",
  },
});

export default GuestPaymentCheckout;

// import React from "react";
// const GuestPaymentCheckout = (props) => {
//   return <p>Hi</p>;
// };
// export default GuestPaymentCheckout;
