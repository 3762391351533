import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";

import "@dotlottie/player-component";
import Lottie from "lottie-web";
import payment_processing_light from "../../assets/lottie-animations/payment-processing.json";
import payment_processing_dark from "../../assets/lottie-animations/payment-processing-dark.json";
import miscService from "../../services/misc.service";

const VouchUPITimer = (props) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((oldtimer) => oldtimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer > 320) props?.onCollectFailed();
    else {
      if (timer > 10 && timer <= 600 && timer % 15 == 0) checkCollectStatus();
    }
  }, [timer]);
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#payment-success-lottie-div"),
      animationData: animation,
      loop: false,
    });
  }, []);
  const checkCollectStatus = async () => {
    const statusPayload = await miscService.check_upi_collection_status({
      data: props.data,
    });
    console.log(statusPayload);

    if (statusPayload?.status == "completed") props?.onCollectSuccess(statusPayload);
    if (statusPayload?.status == "declined") props?.onCollectFailed("The Payment has Failed as Payer has declined the Payment Request");
  };
  const customize_style = (event, style) => {
    var style = props.customize_style(event, style);
    return style;
  };
  var transition_theme = customize_style("transition_theme");
  if (transition_theme.theme == "dark") {
    var animation = payment_processing_dark;
    // var animation = "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/payment_processing_dark.lottie";
  } else {
    var animation = payment_processing_light;
    // var animation = "https://b2bimages-1122.s3.ap-south-1.amazonaws.com/payment_processing_light.lottie";
  }
  return (
    <View
      style={{
        backgroundColor: transition_theme.backgroundColor ? transition_theme.backgroundColor : "#fff",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <View style={{ backgroundColor: transition_theme.backgroundColor ? transition_theme.backgroundColor : "#fff", width: 500, height: 500 }}>
        {/* <LottieView options={{ animationData: animation, autoplay: true, loop: true }} /> */}
        {/* <LottieView source={animation} autoPlay loop /> */}
        <div id="payment-success-lottie-div" style={{ width: 500, height: 500, alignItems: "center" }} />
        {/* <dotlottie-player autoplay controls loop mode="normal" src={animation} style="width: 320px"></dotlottie-player> */}
      </View>

      <View
        style={{
          width: 300,
          height: 20,
          backgroundColor: "#DDD",
          borderRadius: 100,
          marginBottom: 50,
          overflow: "hidden",
        }}
      >
        <View
          style={customize_style("banner", {
            backgroundColor: transition_theme.header.fontColor ? transition_theme.header.fontColor : "#fff",
            width: `${(timer * 100) / 300}%`,
            height: 20,
            borderRadius: 100,
          })}
        ></View>
      </View>

      <Text
        style={{
          fontSize: 16,
          color: transition_theme.header.fontColor ? transition_theme.header.fontColor : "#fff",
          padding: 14,
          textAlign: "left",
        }}
      >
        Waiting for confirmation
      </Text>

      <Text
        style={{
          fontFamily: "Roboto",
          color: transition_theme.header.fontColor ? transition_theme.header.fontColor : "#fff",
          fontSize: 12,
          lineHeight: 30,
        }}
      >
        1. Open your UPI application on your mobile{"\n"}2. Enter the MPIN to complete the payment{"\n"}This UPI payment request will expire in 10 minute(s).
      </Text>

      <Text
        style={{
          fontFamily: "Roboto",
          fontSize: 18,
          marginTop: "10%",
          color: transition_theme.header.fontColor ? transition_theme.header.fontColor : "#f44336",
        }}
      >
        Do not Refresh the Page or navigate Away
      </Text>
    </View>
  );
};

export default VouchUPITimer;
