import React, { useState } from "react";
import { View, Text } from "react-native";
import Decimal from "decimal.js";
import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
import * as Device from 'expo-device';

const BankTransferOption = (props) => {
  let [fontsLoaded] = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
  });
  var [convenience_fees, setConvenienceFees] = useState(0);
  var [total_amount, setTotalAmount] = useState(0);

  if (
    props.order_details.hasOwnProperty("convenience_fees") &&
    props.order_details.convenience_fees.hasOwnProperty("VAN") &&
    props.order_details.convenience_fees.DC != 0 &&
    total_amount == 0
  ) {
    console.log("convenience fees : ".convenience_fees);
    if (props.order_details.convenience_fees.DC != 0) {
      setConvenienceFees("(Including " + ((props.order_details.convenience_fees.VAN / props.order_details.amount) * 100).toFixed(2) + "% fee)");
      setTotalAmount(Number(Decimal(Number(props.order_details.amount)).add(Number(props.order_details.convenience_fees.VAN))));
    }
  } else if (total_amount == 0) {
    setConvenienceFees("(Including 0% fee)");
    setTotalAmount(Number(Decimal(Number(props.order_details.amount))));
  }
  return (
    <View style={{}}>
      <View
        style={props.customize_style("card", {
          borderWidth: 1,
          borderColor: "#DDDDDD",
          borderRadius: '0.625rem',
          paddingHorizontal: Device.deviceType == '1' ? '1rem' : '2rem',
          paddingVertical: Device.deviceType == '1' ? '1rem' : '1rem',
        })}
      >
        <View style={{ flexDirection: "row", paddingBottom: '1.15rem' }}>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
              fontFamily: "Roboto_400Regular",
              color: "#000",
              marginRight: '2rem'
            })}
          >
            You will Pay ₹{!Number.isNaN(Number(total_amount)) ? Number(props.amount).toLocaleString() : total_amount}
          </Text>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.625rem' : '0.875rem',
              fontFamily: "Roboto_400Regular",
              color: "#737373",
              color: "#737373",
            })}
          >
            {convenience_fees}
          </Text>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: '1rem' }}>
          <Text style={props.customize_style("header", {
            fontSize: Device.deviceType == '1' ? '0.875rem' : '1.5rem',
            fontFamily: "Roboto_400Regular",
            color: "#$000"
          })}>
            Bank Transfer
          </Text>
        </View>
        <View>
          <Text style={props.customize_style("subText", {
            fontSize: Device.deviceType == '1' ? '0.625rem' : '1.125rem',
            fontFamily: "Roboto_400Regular", color: "#707070", marginBottom: '1rem'
          })}>
            Transfer the amount to the following bank account using
          </Text>
          <Text
            style={props.customize_style("subText", {
              fontSize: Device.deviceType == '1' ? '0.625rem' : '1.125rem',
              fontFamily: "Roboto_400Regular",
              color: "#707070",
              marginBottom:'1rem'
            })}
          >
            IMPS | NEFT | RTGS
          </Text>

          <View
            style={props.customize_style("card", {
              borderWidth: 1,
              borderColor: "#DDDDDD",
              borderRadius: '0.625rem',
              paddingHorizontal: '1rem',
              paddingVertical: '0.5rem',
              marginBottom:'1rem'
            })}
          >
            <Text
              style={props.customize_style("subText", {
                fontSize: Device.deviceType == '1' ? '0.875rem' : '1.125rem',
                fontFamily: "Roboto_400Regular",
                paddingBottom: '1rem',
              })}
            >
              Account Details
            </Text>
            <Text
              style={props.customize_style("subText", {
                fontSize: Device.deviceType == '1' ? '0.75rem' : '1.125rem',
                fontFamily: "Roboto_400Regular",
                color: "#737373",
                paddingBottom: '1rem',
              })}
            >
              A/c No &nbsp;&nbsp;: &nbsp;&nbsp; {props.van}
            </Text>
            <Text style={props.customize_style("subText", {

              fontSize: Device.deviceType == '1' ? '0.75rem' : '1.125rem',
              fontFamily: "Roboto_400Regular", color: "#737373"
            })}>
              IFSC &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;{props.ifsc}
            </Text>
          </View>

          <Text
            style={props.customize_style("header", {
              color: "#737373",
              fontSize: Device.deviceType == '1' ? '0.875rem' : '1.125rem',
            })}
          >
            {" "}
            Important{" "}
          </Text>
          <h />
          <View style={{
            borderBottomWidth: 1,
            borderBottomColor: 'black',
            marginVertical: '0.5rem',
          }} />
          {/* <Text style={props.customize_style("header", { color: "#737373" })}>
            {
              // "\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E\u203E"
              "".padStart(50, "\u203E")
            }
          </Text> */}
          <Text style={props.customize_style("subText", { color: "#737373", paddingBottom:'0.5em', fontSize: Device.deviceType == '1' ? '0.625rem' : '1.125rem',})}>
            1. Transfer Only ₹{!Number.isNaN(Number(total_amount)) ? Number(props.amount).toLocaleString() : total_amount}, any other amount will be rejected
          </Text>
          <Text style={props.customize_style("subText", { color: "#737373", paddingBottom:'0.5em', fontSize: Device.deviceType == '1' ? '0.625rem' : '1.125rem' })}>
            2. Transfer within 48 hours
          </Text>

          <View style={{ alignItems: "flex-start", flexDirection: "row" }}>
            <Text style={props.customize_style("subText", { color: "#737373", paddingBottom:'0.5em', fontSize: Device.deviceType == '1' ? '0.625rem' : '1.125rem' })}>
              3.&nbsp;
            </Text>
            <Text style={props.customize_style("subText", { color: "#737373", paddingBottom:'0.5em', fontSize: Device.deviceType == '1' ? '0.625rem' : '1.125rem' })}>
              Payment status is automatically updated upon receipt of ₹
              {!Number.isNaN(Number(total_amount)) ? Number(props.amount).toLocaleString() : total_amount} into the above account
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default BankTransferOption;
