import React, { Component } from "react";
import { View, Text, TextInput } from "react-native";
import * as Device from 'expo-device';

// import { useFonts, Roboto_300Light, Roboto_400Regular } from "@expo-google-fonts/roboto";
export default class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    //   var [fontsLoaded] = useFonts({
    //     Roboto_300Light,
    //     Roboto_400Regular,
    //   });
  }

  customize_style = (event, style) => {
    if (this.props.customize_style) {
      return this.props.customize_style(event, style);
    }
    return style;
  };
  render() {
    return (
      <View>
        <Text
          style={this.customize_style("header", {
            fontFamily: "Roboto_400Regular",
            fontSize: Device.deviceType == '1' ? '0.75rem' : '1rem',
            color: "#464646",
            paddingLeft: '1em',
          })}
        >
          {this.props.label}
          {this.props.label == "Email" || this.props.label == "Referral code" ? <Text style={{ fontSize: 12 }}> (Optional)</Text> : null}
        </Text>
        <View
          regular
          style={{
            marginTop: '0.5rem',
            backgroundColor: this.props.disabled ? "#AAA" : "#FFF",
            borderRadius: '0.625rem',
            height: '3rem',
            borderColor: "#DCDCDC",
            borderWidth: '1px',

          }}
        >
          <TextInput
            placeholderTextColor={"#AAAAAA"}
            placeholder={this.props.placeholder}
            textContentType={this.props.type ? this.props.type : "name"}
            style={{
              fontSize: Device.deviceType == '1' ? '0.875rem' : '1rem',
              paddingHorizontal: '0.5rem',
              paddingVertical: '0.9rem',
              borderRadius: '0.625rem',
              backgroundColor: this.props.disabled ? "#DCDCDC" : null,
              fontFamily: "Roboto_400Regular",
            }}
            onPress={this.props.clicked}
            onChangeText={this.props.onChangeText}
            onBlur={this.props.onBlur}
            value={this.props.value}
            keyboardType={this.props.keyboard ? this.props.keyboard : "default"}
            autoFocus={this.props.autoFocus ? true : false}
            disabled={this.props.disabled ? this.props.disabled : false}
            maxLength={this.props.maxLength ? this.props.maxLength : null}
          />
        </View>
      </View>
    );
  }
}
